import { FC, useState } from 'react'
import { Button } from '@microservices/wiskey-react-components'
import { Box, Grid, List, ListItem } from '@mui/material'

import { InputFileUpload } from '@components/InputFileUpload'

import { useUploadFlightsMutation } from '@redux/api/slots.api'

export const FileUploadDialog: FC = () => {
  const [files, setFiles] = useState<FileList | null>(null)

  const [uploadFlights] = useUploadFlightsMutation()

  const handleSetFile = (files: FileList | null) => {
    if (!files) {
      return
    }

    setFiles(files)
  }

  const handeSend = () => {
    if (!files || !files.length) {
      return
    }

    const formData = new FormData()
    formData.append('file', files[0])
    //
    // for (const file in files) {
    //   formData.append('file', file)
    // }

    uploadFlights(formData).then(() => {
      setFiles(null)
    })
  }

  const disabledButton = !files || !files.length

  return (
    <Grid container direction='column' height={'100%'} justifyContent={'space-between'}>
      <Grid container item flexDirection='column'>
        <Box ml={2} mt={3} width='300px'>
          <InputFileUpload onChange={handleSetFile} />
        </Box>
        <List dense>
          {files &&
            files.length &&
            Array.from(files).map((file, index) => {
              return <ListItem key={index}>{file.name}</ListItem>
            })}
        </List>
      </Grid>
      <Grid item>
        <Button disabled={disabledButton} sx={{ ml: 1 }} variant={'contained'} onClick={handeSend}>
          Send file
        </Button>
      </Grid>
    </Grid>
  )
}
