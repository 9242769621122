import { FC } from 'react'
import { Button } from '@microservices/wiskey-react-components'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

type InputFileUploadProps = {
  onChange: (files: FileList | null) => void
}

export const InputFileUpload: FC<InputFileUploadProps> = ({ onChange }) => {
  return (
    <Button
      component='label'
      role={undefined}
      startIcon={<CloudUploadIcon />}
      tabIndex={-1}
      variant='contained'
    >
      Upload file
      <VisuallyHiddenInput
        type='file'
        accept='.csv'
        // multiple
        onChange={event => onChange(event.target.files)}
      />
    </Button>
  )
}
