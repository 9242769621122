import { Navigate } from 'react-router-dom'

import { ReturnTransformCommonParametersToValues } from '@helpers'
import { ROUTES } from '@constants'

export const SwitchRouter = (initialCommonParameters: ReturnTransformCommonParametersToValues) => {
  const context = localStorage.getItem('context')

  if (context === null) {
    return <Navigate replace to={ROUTES.CHOOSE_CONTEXT} />
  }

  if (initialCommonParameters.ganttChart) {
    return <Navigate replace to={initialCommonParameters.ganttChart} />
  }

  if (initialCommonParameters.view) {
    return <Navigate replace to={initialCommonParameters.view} />
  }

  return <Navigate replace to={ROUTES.EMPTY} />
}
