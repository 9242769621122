import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PageContext } from '@pages/FormCreateOrEdit'
import { useInputs } from '@pages/FormCreateOrEdit/components/AddFieldDialog/hooks/useInputs'

import {
  useFetchAllFormByObjectCodeQuery,
  useFetchAllObjectQuery,
  useFetchAllViewQuery,
  useFetchObjectByCodeQuery,
} from '@redux/api'

import { usePrompt } from '@hooks'
import { generateDefaultObjectByFields, getEntityTypeForRequest } from '@helpers'
import {
  BIND_TYPE,
  ENTITY,
  FIELD_VALUE_FORMAT,
  FIELD_VALUE_TYPE,
  OBJECT_FIELD_TYPE,
} from '@constants'
import { ObjectFieldDTO } from '@types'

import { FieldForm, getDefaultValue } from '../helpers/getDefaultValue'
import { useHandlers } from '../hooks/useHandlers'

export const useAddFieldDialog = () => {
  const { t } = useTranslation()
  const defaultValues: FieldForm = getDefaultValue()
  const methods = useForm<FieldForm>({ defaultValues })
  const [isLoading, setLoading] = useState(true)
  const [isEdit, setIsEdit] = useState(true)

  const {
    reset,
    formState: { isDirty },
    watch,
    clearErrors,
    setValue,
    getValues,
  } = methods

  const watchBinding = watch('bindType')
  const watchObjectValue = watch('objectValue')
  const watchEdit = watch('editField')
  const watchJson = watch('json')

  const {
    addField,
    currentRow,
    handleCloseModal,
    modalType,
    editField,
    formCode,
    modifyObjectFields,
    objectFieldsForFilter, // Исходные поля
    bindingValuesFieldRender,
    bindingValuesColumns,
    handleSetModalProperty,
    mainTable: { rows: fields },
    toggleOpenScriptValueDialog,
  } = useContext(PageContext)
  usePrompt({ when: isDirty })
  const [objectField, setObjectField] = useState<ObjectFieldDTO>()
  const embeddedObjectCode = objectField?.model ?? ''

  const valueType = objectField?.valueType || null
  const { data: objectForField } = useFetchObjectByCodeQuery(embeddedObjectCode, {
    skip: !embeddedObjectCode,
  })
  const { data: forms } = useFetchAllFormByObjectCodeQuery(embeddedObjectCode, {
    skip: !embeddedObjectCode,
  })
  const { data: dropdownEntities } = useFetchAllViewQuery(
    {
      objectCode: embeddedObjectCode,
      viewType: getEntityTypeForRequest(ENTITY.DROP_DOWN_ENTITY),
    },
    { refetchOnMountOrArgChange: true, skip: !embeddedObjectCode }
  )

  const { data: objects } = useFetchAllObjectQuery()

  const srcObj = generateDefaultObjectByFields(objectFieldsForFilter || [])

  const watchValue = watch('value')
  const watchDropdownList = watch('dropDownListCode')
  const watchDropdownWindow = watch('dropDownWindowCode')
  const watchDefaultPlaceholder = watch('isDefaultPlaceholder')
  const watchAsCheckbox = watch('asCheckbox')
  const watchAsDuration = watch('asDuration')

  // TODO дубликат
  useEffect(() => {
    const field = objectFieldsForFilter?.find(field => field.name === watchValue)
    setObjectField(field ? field : undefined)
    clearErrors()

    if (currentRow && field && currentRow?.value === field?.name) {
      setValue('objectValue', currentRow?.objectValue)

      return
    }

    if (watchBinding !== BIND_TYPE.JS) {
      setValue('objectValue', t('placeholder.objectValue'))
    }
  }, [objectFieldsForFilter, watchValue])

  // Когда поля asСheckbox и asDuration скрываются, нужно ресетить их значения, чтобы случайно не отправилось true
  // при иных valueType
  useEffect(() => {
    if (objectField?.type === OBJECT_FIELD_TYPE.ENUM) {
      return
    }

    if (valueType !== FIELD_VALUE_TYPE.INTEGER && valueType !== FIELD_VALUE_TYPE.BOOLEAN) {
      if (watchAsCheckbox && isDirty) {
        setValue('asCheckbox', false)
      }
    }

    if (valueType !== FIELD_VALUE_TYPE.INTEGER) {
      if (watchAsDuration && isDirty) {
        setValue('asDuration', false)
      }
    }
  }, [valueType])

  useEffect(() => {
    setIsEdit(Boolean(watchEdit))
  }, [watchEdit])

  useEffect(() => {
    if (currentRow) {
      const field = objectFieldsForFilter?.find(field => field.name === currentRow.value)

      const preFillRootObjectCode = currentRow.preFillLink
        ? currentRow.preFillLink[0].objectCode
        : null

      const preFillRootObject = preFillRootObjectCode
        ? objects?.find(object => object.code === preFillRootObjectCode)
        : null

      reset({
        ...currentRow,
        valueType,
        objectFormCode: currentRow?.objectFormCode
          ? {
              id: currentRow?.objectFormCode,
              label: currentRow?.objectFormTitle,
            }
          : null,
        params: currentRow.params ? JSON.stringify(currentRow.params) : '',
        json: Boolean(currentRow.params),
        required: Boolean(currentRow?.userRequired),
        dropDownListCode: !currentRow.dropDownList
          ? null
          : {
              id: currentRow.dropDownList.code,
              label: currentRow.dropDownList.title,
            },
        dropDownWindowCode: !currentRow.dropDownWindow
          ? null
          : {
              id: currentRow.dropDownWindow.code,
              label: currentRow.dropDownWindow.title,
            },
        dropDownListFilters: currentRow.dropDownListFilters || [],
        dropDownWindowFilters: currentRow.dropDownWindowFilters || [],
        placeholderValue: currentRow.placeholderValue || '',
        isDefaultPlaceholder: Boolean(currentRow.isDefaultPlaceholder),
        formatDate: currentRow.formatDate
          ? { id: currentRow.formatDate, label: currentRow.formatDate }
          : null,
        asCheckbox:
          currentRow.valueFormat === FIELD_VALUE_FORMAT.NUMBER ||
          currentRow.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN,
        asDuration: currentRow.valueFormat === FIELD_VALUE_FORMAT.TIME,
        preFillSourceObject: preFillRootObject
          ? { id: preFillRootObject.code, label: preFillRootObject.title }
          : null,
        isMultiline: currentRow.isMultiline,
      })
      setLoading(false)
      setObjectField(field ? field : undefined)
    }
  }, [currentRow])

  useEffect(() => {
    if (objectField?.required) {
      setValue('required', true)
    }
  }, [objectField])

  useEffect(() => {
    handleSetModalProperty?.('field')
  }, [])

  useEffect(() => {
    if (currentRow?.userRequired && watchBinding === BIND_TYPE.JS) {
      setValue('required', false)
    }
  }, [watchBinding])

  const [isFieldsLoading, setFieldsLoading] = useState(true)
  useEffect(() => {
    if (bindingValuesFieldRender) {
      setFieldsLoading(false)
    }
  }, [bindingValuesFieldRender])

  useEffect(() => {
    if (watchValue !== currentRow?.value) {
      setValue('objectFormCode', null)

      if (objectField?.required === false) {
        setValue('required', false)
      }
    }
  }, [watchValue, objectField])

  useEffect(() => {
    if (watchBinding === BIND_TYPE.JS) {
      const value =
        currentRow?.value && currentRow.bindType === BIND_TYPE.JS ? currentRow.value : ''

      setValue('value', value)
    }
    if (watchBinding === BIND_TYPE.FIELD) {
      const value =
        currentRow?.value && currentRow.bindType === BIND_TYPE.FIELD
          ? currentRow.value
          : t('placeholder.value')

      setValue('value', value)
    }
  }, [watchBinding])

  const { handlers, state } = useHandlers({
    addField,
    editField,
    formCode,
    handleCloseModal,
    modalType,
    objectField,
    isEdit,
    embeddedObjectCode,
    defaultValues,
    currentRow,
    methods,
  })

  const { inputs } = useInputs({
    modifyObjectFields,
    objectFieldsForFilter,
    currentRow,
    dropdownEntities,
    forms,
    embeddedObjectCode,
    fields,
    methods,
    objectForField,
    bindingValuesColumns,
    objectField,
    setIsEdit,
    valueType,
    srcObj,
    toggleOpenScriptValueDialog,
    modalType,
    isEdit,
    objects,
  })

  return {
    data: {
      methods,
      valueType,
    },
    state: {
      isLoading,
      isFieldsLoading,
      isEdit,
      ...state,
    },
    handlers,
    inputs,
  }
}
