import { ReactNode, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import i18next from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Divider, Typography } from '@mui/material'

import { ErrorValidJSType } from '@helpers'
import { GANTT_BIND_TYPE, GENERATOR_INPUT_TYPE } from '@constants'
import { AutocompleteOption, SelectOption } from '@types'

import { BIND_TYPE_INPUTS_FORM_TYPE, ConfigField } from '@gantt/components/GanttCreateOrEdit/types'
import { getBindTypeOptionsByEnum } from '@gantt/helpers'
import { OptionsFilter } from '@gantt/types'

import { InputType, ValueInputFactory } from './ValueInputFactory'

export type TextDict = {
  js?: string
  static?: string
  script?: string
  json?: string
  field?: string
  field_array?: string
  command?: string
  form?: string
  custom?: string
}

type Props = {
  hasCommands?: boolean
  containerName?: string
  bindTypeOptions?: SelectOption[]
  formType: BIND_TYPE_INPUTS_FORM_TYPE
  hintDict?: TextDict
  fieldsetMarkup?: boolean
  fieldsetTitle?: string
  divide?: boolean
  inputType?: InputType
  bindTypeTitle?: string
  blockLabel?: string
  showBinding?: boolean
  language?: string
  valueInputLabel?: string
  isDisabled?: boolean
  watchedObject?: AutocompleteOption<string> | null
  prefix?: string
  children?: ReactNode
  placeholderDict?: TextDict
  valueOptions?: (AutocompleteOption<string | number> & { default?: boolean })[]
  validator?: (value: string) => ErrorValidJSType | undefined
  onSaveField?: (value: ConfigField) => void
  onAfterValidationScript?: (state: boolean) => void
  optionsFilter?: OptionsFilter
}

export const BindTypeInputs = ({
  hasCommands,
  containerName,
  bindTypeOptions,
  formType,
  hintDict,
  fieldsetMarkup = false,
  fieldsetTitle,
  divide = false,
  inputType,
  bindTypeTitle,
  blockLabel,
  showBinding = false,
  language = 'js',
  valueInputLabel,
  isDisabled,
  watchedObject,
  prefix,
  children,
  placeholderDict,
  valueOptions,
  validator,
  onSaveField,
  onAfterValidationScript,
  optionsFilter,
}: Props) => {
  const { watch, setValue } = useFormContext()
  const path = (key: string) => (containerName ? `${containerName}.${key}` : `${key}`)
  const watchBinding: keyof TextDict = watch(path('bindType'))
  const selectOptions = bindTypeOptions || getBindTypeOptionsByEnum(GANTT_BIND_TYPE)
  const hint = useMemo(() => hintDict && hintDict[watchBinding], [hintDict, watchBinding])

  const placeholder = useMemo(
    () => placeholderDict && placeholderDict[watchBinding],
    [placeholderDict, watchBinding]
  )

  const handleClearOnChangeBinding = useCallback((bindType: string | number) => {
    setValue(path(''), { bindType })
  }, [])

  const filteredOptions = useMemo(() => {
    if (optionsFilter && valueOptions) {
      return optionsFilter(valueOptions, watchBinding)
    }

    return valueOptions || []
  }, [valueOptions, optionsFilter])

  return (
    <Box component={fieldsetMarkup ? 'fieldset' : 'div'} mb={2}>
      {fieldsetTitle && <legend>{fieldsetTitle}</legend>}
      {blockLabel && (
        <Typography pb={1} pt={1} variant={'body1'}>
          {blockLabel}
        </Typography>
      )}
      {(Boolean(selectOptions && selectOptions?.length > 1) || showBinding) && (
        <FormInput
          inputType={GENERATOR_INPUT_TYPE.SELECT}
          label={bindTypeTitle ? bindTypeTitle : i18next.t('label.bindType')}
          name={path('bindType')}
          selectOptions={selectOptions}
          onChangeSelect={handleClearOnChangeBinding}
        />
      )}
      <ValueInputFactory
        formType={formType}
        getPath={path}
        hasCommands={hasCommands}
        hint={hint}
        inputType={inputType}
        isDisabled={isDisabled}
        language={language}
        options={filteredOptions}
        optionsFilter={optionsFilter}
        placeholder={placeholder}
        prefix={prefix}
        validator={validator}
        valueInputLabel={valueInputLabel}
        watchBinding={watchBinding}
        watchedObject={watchedObject}
        onAfterValidationScript={onAfterValidationScript}
        onSaveField={onSaveField}
      >
        {children}
      </ValueInputFactory>
      {divide && <Divider sx={{ borderWidth: 1, mb: 1, mt: 1 }} />}
    </Box>
  )
}
