import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import isString from 'lodash/isString'
// import {
//   GridColumns,
//   GridColumnVisibilityModel,
//   GridEnrichedColDef,
//   GridPinnedColumns,
//   GridPinnedPosition,
//   MuiEvent,
//   useGridApiRef,
// } from '@microservices/wiskey-react-components/DataGrid'
import {
  Checkbox,
  GridCellParams,
  GridColumns,
  GridColumnVisibilityModel,
  GridEnrichedColDef,
  GridPinnedColumns,
  GridPinnedPosition,
  GridValidRowModel,
  MuiEvent,
  useGridApiRef,
} from '@microservices/wiskey-react-components'
import { Box, Interpolation, Theme } from '@mui/material'

import { HeaderRender } from '@pages/ConfiguredEntity/components/HeaderRender'
import { OpenFormProps } from '@pages/ConfiguredEntity/types'

import {
  useFetchAllObjectQuery,
  useFetchAllViewQuery,
  useFetchFormQuery,
  useFetchViewByIdNoCacheMutation,
} from '@redux/api'
import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'
import {
  useFetchRestrictionsByViewIdNoCacheMutation,
  useUpdateRestrictionsMutation,
} from '@redux/api/restriction.api'

import { useAppDispatch, useContextMenu, useSubscription } from '@hooks'
import {
  generateDataGridActionsColumn,
  generateDataGridLinkColumn,
  getActionBoolean,
  getColumnParametersFromArray,
  getLocalStorageContext,
  transformCommonParametersToValues,
} from '@helpers'
import {
  BIND_TYPE,
  CLICK_EVENT_TYPE,
  COMMON_PARAMETERS,
  DEFAULT_BACKGROUND_PINNED_COLUMN,
  DEFAULT_HEADER_STYLES_CLASSNAME,
  ENTITY,
  EVENT_CODE,
  GLOBAL_PARAMETER_CODE,
  MIN_VIEW_COLUMN_WIDTH,
  PASSED_FORM_DATA_VARIANT,
  PINNED_COLUMN,
  ROUTES,
  SEARCH_RULE_EXCEPTION,
  SEARCH_RULE_TYPE,
  SIZE_PAGE,
} from '@constants'
import {
  AutocompleteOption,
  EntityType,
  ObjectDataRecord,
  RestrictionData,
  RestrictionDTO,
  StyleSetting,
  ViewRow,
} from '@types'

import {
  findActionByEventCode,
  getColumnFieldName,
  getColumnFieldNameForSort,
  getColumnParameterStyles,
  getObjectCodeUrl,
  sortByOrder,
  transformFixRestrictions,
} from '../helpers'
import getClassNameGlobalStyles, {
  NAMES_OF_ENTITIES_FOR_STYLES,
} from '../helpers/getClassNameGlobalStyles'
import { getInitialColumnVisibilityModel } from '../helpers/getInitialColumnVisibilityModel'
import { getSortByColumns } from '../helpers/getSortByColumns'
import { getSortKeysOfColumnsForQuickSearch } from '../helpers/getSortKeysOfColumnsForQuickSearch'

import { useGetActions } from './useGetActions'
import { useHandleColumnDrag } from './useHandleColumnDrag'
import { useHandlers } from './useHandlers'

type CellsObjectStateValueType = {
  className: string
  columnName: string
  columnCode: string
  columnValue: string | number
  objectStateValue: string
  styleSettings: StyleSetting
}

type useConfiguredViewParams = {
  entityCode: string | null
  entityId: number | string | null
  path: string
  isNestedView?: boolean
  parentFormIsDirty?: boolean
  getLeaveForm?: () => boolean
  onResetEditForm?: () => void
  onSelectPickerOption?: (objectId: string) => void
  type: ENTITY
  formObjectId?: string
  formObjectCode?: string
  formElementId?: number
  valueId?: number
  fieldId?: number
  selectedPickerOption?: string
  selectedPickerOptionValue?: string | AutocompleteOption
  onMultiSelectPickerOption?: (value: AutocompleteOption[]) => void
  multipleSelectPickerOption?: AutocompleteOption[]
  objectValueNameOption?: string
  handleOpenForm?: (
    formProps: OpenFormProps,
    event: MouseEvent<HTMLButtonElement> | MuiEvent<MouseEvent>,
    type: CLICK_EVENT_TYPE
  ) => void
  search?: string
  pathname: string
  dialogId?: string
  isListControlDialogWindow: boolean
  isViewDialogWindow?: boolean
  customPageSize: number

  entity: EntityType | null
  onSetEntity: (value: EntityType | null) => void
  readonly?: boolean
}

export const useConfiguredEntity = ({
  entityCode,
  entityId,
  path,
  isNestedView,
  parentFormIsDirty,
  type,
  getLeaveForm,
  onResetEditForm,
  onSelectPickerOption,
  formObjectId,
  formObjectCode,
  formElementId,
  fieldId,
  valueId,
  selectedPickerOptionValue,
  onMultiSelectPickerOption,
  multipleSelectPickerOption,
  objectValueNameOption,
  handleOpenForm,
  search,
  pathname,
  dialogId,
  isListControlDialogWindow,
  customPageSize,
  entity,
  onSetEntity,
  readonly,
  isViewDialogWindow,
}: useConfiguredViewParams) => {
  const context = getLocalStorageContext()

  const { t } = useTranslation()
  const isAssistiantSearch = path === ROUTES.SEARCH && !isNestedView
  const [visibleColumns, setVisibleColumns] = useState<ViewRow[]>([])

  const { data: objects } = useFetchAllObjectQuery(undefined, { skip: !isAssistiantSearch })

  const [fetchEntity, { isLoading: isLoadingView }] = useFetchViewByIdNoCacheMutation()
  const [fetchEntityRestrictions, { isSuccess: isEntityRestrictionsChanged }] =
    useFetchRestrictionsByViewIdNoCacheMutation()
  const [
    updateRestrictions,
    { isSuccess: isUpdateRestrictionsSuccess, isLoading: isLoadingRestrictions },
  ] = useUpdateRestrictionsMutation()

  const [entityRestrictions, setEntityRestrictions] = useState<RestrictionData>()

  const [globalStyles, setGlobalStyles] = useState<Interpolation<Theme>>({})
  const [formCode, setFormCode] = useState<string>()
  const { data: form, isSuccess: formIsSuccess } = useFetchFormQuery(formCode, { skip: !formCode })
  const dispatch = useAppDispatch()
  const viewColumns = useMemo(() => entity?.columns?.filter(col => col.visibility) || [], [entity])
  const actions = entity?.actions

  // В dropdown window быстрый поиск есть всегда
  const hasQuickSearch = entity?.hasQuickSearch || type === ENTITY.DROP_DOWN_ENTITY
  // const hasQuickSearch = entity?.hasQuickSearch
  const objectCode = entity?.objectCode || ''

  const apiRef = useGridApiRef()

  const { data: globalColumnHeaderStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_HEADER_STYLES,
  })

  const { data: globalColumnTextStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_TEXT_STYLES,
  })

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  const [columns, setColumns] = useState<GridColumns<ObjectDataRecord>>([])
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({})
  const [initialColumnVisibilityModel, setInitialColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({})
  const [objectStyles, setObjectStyles] = useState<Interpolation<Theme>>({})

  const handleSetColumnsVisibilityModel = (value: GridColumnVisibilityModel) =>
    setColumnVisibilityModel(value)

  const handleGetEntityRestrictionsData = () => {
    fetchEntityRestrictions({
      viewId: entityId as string,
    })
      .unwrap()
      .then(entityRestrictions => {
        setEntityRestrictions(entityRestrictions)
      })
  }

  const handleUpdateRestrictions = (restrictions: RestrictionDTO[]) => {
    return updateRestrictions(restrictions)
  }

  const handleGetEntityThenRestrictions = (entityId: string | number) => {
    fetchEntity({ id: entityId })
      .unwrap()
      .then(entity => {
        onSetEntity(entity)
      })
      .then(() => {
        fetchEntityRestrictions({
          viewId: entityId as string,
        })
          .unwrap()
          .then(entityRestrictions => {
            setEntityRestrictions(entityRestrictions)
          })
      })
  }

  const { state, data, handlers } = useHandlers({
    entityCode,
    entityId,
    path,
    apiRef,
    entity,
    objectCode,
    actions,
    isNestedView,
    getLeaveForm,
    onResetEditForm,
    onSelectPickerOption,
    onMultiSelectPickerOption,
    initialCommonParameters,
    initialColumnVisibilityModel,
    type,
    formObjectId,
    formObjectCode,
    formElementId,
    fieldId,
    valueId,
    multipleSelectPickerOption,
    objectValueNameOption,
    columns,
    entityRestrictions: entityRestrictions ?? ({} as RestrictionData),
    isLoadingRestrictions,
    handleUpdateRestrictions,
    onSetColumnVisibilityModel: handleSetColumnsVisibilityModel,
    handleOpenForm,
    search,
    isAssistiantSearch,
    dialogId,
    customPageSize,
    handleGetEntityRestrictionsData,
    viewColumns,
    onSetColumns: setColumns,
    readonly,
    contextId: context?.id,
  })

  const {
    samePageFormsData,
    currentDynamicForm,
    currentStaticForm,
    selectedRowIds,
    searchAssistantValue,
    searchRule,
    valueSearchFilter,
    isSuccessDeleteRestrictions,
  } = state

  const {
    cellRenderer,
    handleSetSelectedRowIds,
    handleObjectSubscribe,
    handleEdit,
    handleAdditionalButtonClick,
    handleSetTotalElements,
    handleSetPinnedColumns,
    handleSetColumnNameCheckBox,
    handleSetSortKeysColumnCheckBox,
    handleSetSortKeysForQuickSearch,
    handleSetSearchRule,
    handleSetGlobalFilter,
    refreshObjectData,
  } = handlers

  const {
    staticFormsData,
    dynamicFormsData,
    totalCountElements,
    objectData,
    isLoadingObjectData,
    currentSort,
  } = data

  useEffect(() => {
    if (!entityId || !isUpdateRestrictionsSuccess) {
      return
    }

    handleGetEntityThenRestrictions(entityId)
  }, [isUpdateRestrictionsSuccess, isSuccessDeleteRestrictions])

  const handleResetEntity = () => {
    onSetEntity(null)
    handleSetColumnNameCheckBox({})
    handleSetSortKeysColumnCheckBox({})
    handleSetSortKeysForQuickSearch([])
    handleSetSearchRule(
      type === ENTITY.DROP_DOWN_ENTITY
        ? {
            name: SEARCH_RULE_TYPE.CONTAIN,
            exception: SEARCH_RULE_EXCEPTION[SEARCH_RULE_TYPE.CONTAIN],
          }
        : {
            name: SEARCH_RULE_TYPE.EQUAL,
            exception: SEARCH_RULE_EXCEPTION[SEARCH_RULE_TYPE.EQUAL],
          },
      true
    )
    handleSetGlobalFilter('')
  }

  useEffect(() => {
    if (entityId) {
      handleResetEntity()

      fetchEntity({ id: entityId })
        .unwrap()
        .then(entity => {
          onSetEntity(entity)

          // Логика для первоначальной (либо при смене вью) подгрузке данных для ассистента поиска
          if (isAssistiantSearch) {
            const viewColumns = entity.columns?.filter(col => col.visibility) || []
            const initialColumnsSortKey = getInitialColumnVisibilityModel(
              viewColumns,
              true,
              SEARCH_RULE_TYPE.EQUAL
            )
            const pageSizeForRequest = customPageSize * 2
            const sizeParameter = Number(initialCommonParameters.pagination)
            refreshObjectData(
              {
                code: entity.objectCode,
                viewId: entity.id,
                size:
                  pageSizeForRequest > sizeParameter
                    ? pageSizeForRequest.toString()
                    : sizeParameter || SIZE_PAGE,
                body: searchAssistantValue,
                sortKeysForQuickSearch: getSortKeysOfColumnsForQuickSearch(initialColumnsSortKey),
                searchValue: '',
                searchRule: SEARCH_RULE_TYPE.EQUAL,
                sort: getSortByColumns(viewColumns),
              },
              false
            )
          }
        })

      fetchEntityRestrictions({
        viewId: entityId as string,
      })
        .unwrap()
        .then(entityRestrictions => {
          setEntityRestrictions(entityRestrictions)
        })
    }
  }, [entityId])

  useSubscription(getObjectCodeUrl(objectCode), handleObjectSubscribe)

  const { data: allViews } = useFetchAllViewQuery()

  // Колонка с чекбоксами у dropdown window
  const selectionColumn: GridEnrichedColDef = {
    field: 'select',
    headerName: t('configuredView.select'),
    type: 'select',
    headerAlign: 'center',
    align: 'center',
    width: 75,
    resizable: false,
    hideable: false,
    pinnedColumn: PINNED_COLUMN.LEFT,
    sortable: false,
    headerClassName: `${DEFAULT_HEADER_STYLES_CLASSNAME}`,
    renderCell: params => {
      return (
        <Box>
          <Checkbox
            checked={selectedRowIds.includes(params.row._id as string)}
            disabled={readonly}
            sx={{
              input: {
                zIndex: theme => theme.zIndex.modal + 15,
              },
              color: theme => theme.palette.color.ddwCheckbox,
              '&.Mui-checked': {
                color: theme => theme.palette.color.ddwCheckbox,
              },
              '&.Mui-disabled': {
                color: theme => theme.palette.grey['400'],
                pointerEvents: 'fill',
              },
            }}
          />
        </Box>
      )
    },
  }

  const areAllColumnsHidden = useMemo(
    () =>
      Object.keys(columnVisibilityModel).filter(modelKey => !columnVisibilityModel[modelKey])
        .length === entity?.columns?.length,
    [columnVisibilityModel, entity]
  )

  useHandleColumnDrag(
    apiRef,
    entityId as string,
    entityRestrictions,
    handleUpdateRestrictions,
    isLoadingView
  )

  useEffect(() => {
    setVisibleColumns(viewColumns)
  }, [viewColumns])

  useEffect(() => {
    if (totalCountElements === 0) {
      handleSetTotalElements(0)
    }
    if (totalCountElements) {
      handleSetTotalElements(totalCountElements)
    }
  }, [totalCountElements])

  useEffect(() => {
    if (type === ENTITY.DROP_DOWN_ENTITY && selectedPickerOptionValue) {
      if (!selectedRowIds.length) {
        handleSetSelectedRowIds(
          isString(selectedPickerOptionValue)
            ? selectedPickerOptionValue
            : selectedPickerOptionValue.id.toString()
        )
      }
    }
  }, [objectData, isLoadingObjectData])

  useEffect(() => {
    const currentStaticFormData = staticFormsData[currentStaticForm]
    const currentDynamicFormData = dynamicFormsData[currentDynamicForm]
    const lastSamePageFormVariant = samePageFormsData.at(-1)?.variant

    if (!lastSamePageFormVariant) {
      return
    }

    if (lastSamePageFormVariant === PASSED_FORM_DATA_VARIANT.DYNAMIC && currentDynamicFormData) {
      handleSetSelectedRowIds(currentDynamicFormData.rowId as string)
    }

    if (lastSamePageFormVariant === PASSED_FORM_DATA_VARIANT.STATIC && currentStaticFormData) {
      handleSetSelectedRowIds(currentStaticFormData.rowId as string)
    }
  }, [currentStaticForm, currentDynamicForm, samePageFormsData])

  const { onClickLink, onOpenContextMenu, onCloseContextMenu, position } = useContextMenu({
    dialogId,
  })

  const configActions = useGetActions({
    actions: entity?.actions || [],
    onClickLink,
  })

  const [contextMenuRowData, setContextMenuRowData] = useState<GridValidRowModel | null>(null)

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    if (configActions.actionsAvailable.on_row_right_click) {
      event.preventDefault()

      const rowId = event.currentTarget.getAttribute('data-id')

      rowId && setContextMenuRowData(apiRef.current.getRow(rowId))
      onOpenContextMenu(event)
    }
  }

  useEffect(() => {
    setColumns(
      sortByOrder(viewColumns).map(column => {
        const shouldSort = column.bindType === BIND_TYPE.FIELD || !!column.columnToSort

        return {
          field: getColumnFieldName(column),
          headerName: column.title,
          sortable: shouldSort,
          editable: false,
          hideable: !column.pinnedColumn,
          // Если колонка закреплена администратором, то пользователь не может ее открепить/перезакрепить
          pinnable: !column.pinnedColumn,
          sortKey: getColumnFieldNameForSort(column),
          columnValue: column.value,
          minWidth: MIN_VIEW_COLUMN_WIDTH,
          width: column?.restrictions?.width ?? MIN_VIEW_COLUMN_WIDTH,
          order: column?.restrictions?.order ?? column.order,
          renderCell: params => cellRenderer(column, params),
          columnObjectValue: column.objectValue ?? '',
          renderHeader: params => (
            <HeaderRender
              column={column}
              configActions={configActions}
              hasQuickSearch={hasQuickSearch}
              // hasQuickSearch={hasQuickSearch && type !== ENTITY.DROP_DOWN_ENTITY}
              params={params}
            />
          ),
          // TODO Отключил flex, так-как он не работает с width, а свойство width необходимо для ресайза
          // flex: 1,
          // order: Math.round(Math.random() * 100),
          // pinnedColumn: column.pinnedColumn,
          // headerClassName:
          //   column.bindType !== BIND_TYPE.FIELD
          //     ? TABLE_DESCRIPTION.MODIFY
          //     : TABLE_DESCRIPTION.COMMON,
        }
      })
    )

    const initialColumnVisibilityModel = getInitialColumnVisibilityModel(viewColumns)

    setInitialColumnVisibilityModel(initialColumnVisibilityModel)
    setColumnVisibilityModel(initialColumnVisibilityModel)
  }, [entity, isEntityRestrictionsChanged])

  // Эффект для стилизации колонок в соответсвии с их параметрами (глобальные, на уровне вью, на уровне колонки)
  useEffect(() => {
    if (!entity || !globalColumnHeaderStyles || !globalColumnTextStyles) {
      return
    }
    const generateClassesCellObjectStateValue = () => {
      const cellsObjectStateValue: CellsObjectStateValueType[] = []
      if (!viewColumns) {
        return null
      }
      viewColumns.map(column => {
        if (!column.useObjectState) {
          return null
        }
        if (
          column.objectState?.objectStateValues &&
          column.objectState?.objectStateValues.length === 1
        ) {
          cellsObjectStateValue.push({
            className: `cell-object-state-value-column-code-${column.code}-object-field-value-${column.objectState?.objectStateValues[0].objectFieldValue}`,
            columnName: column.title,
            columnCode: column.code,
            columnValue: column.value,
            objectStateValue: column.objectState?.objectStateValues[0].objectFieldValue,
            styleSettings: column.objectState?.objectStateValues[0].styleSetting,
          })
        }
        if (
          column?.objectState?.objectStateValues &&
          column.objectState.objectStateValues.length > 1
        ) {
          column.objectState.objectStateValues.map(objectStateValue => {
            cellsObjectStateValue.push({
              className: objectStateValue.objectFieldValue
                ? `cell-object-state-value-column-code-${column.code}-object-field-value-${objectStateValue.objectFieldValue}`
                : '',
              columnName: column.title,
              columnCode: column.code,
              columnValue: column.value,
              objectStateValue: objectStateValue.objectFieldValue
                ? objectStateValue.objectFieldValue
                : '',
              styleSettings: objectStateValue.styleSetting,
            })
          })
        }
      })

      return cellsObjectStateValue
    }

    const cellsObjectStateValue = generateClassesCellObjectStateValue()

    const changeCellsObjectStateValueTypeToObjectClassesStyles = () => {
      if (cellsObjectStateValue) {
        cellsObjectStateValue.map(cell => {
          if (cell) {
            const textSetting = cell?.styleSettings?.settingText
            setObjectStyles((prev: Record<any, any>) => ({
              ...prev,
              [`.${cell.className}`]: {
                fontFamily: textSetting.fontFamily,
                fontSize: `${textSetting.fontSize}px !important`,
                fontWeight: 'normal',
                fontStyle: 'normal',
                color: `${textSetting.fontColor} !important`,
                background: `${cell.styleSettings.background} !important`,
                border: `2px solid ${cell.styleSettings.settingBorder.color} !important`,
              },
            }))
          }
        })
      }
    }

    changeCellsObjectStateValueTypeToObjectClassesStyles()

    const { transformedParams: globalColumnHeaderParams, styles: globalHeaderStyles } =
      getColumnParameterStyles(globalColumnHeaderStyles)
    const { transformedParams: globalColumnTextParams, styles: globalTextStyles } =
      getColumnParameterStyles(globalColumnTextStyles)

    const globalStylesNames = getClassNameGlobalStyles(
      NAMES_OF_ENTITIES_FOR_STYLES.GLOBAL,
      entity,
      type
    )
    const entityStylesNames = getClassNameGlobalStyles(
      NAMES_OF_ENTITIES_FOR_STYLES.ENTITY,
      entity,
      type
    )

    let borderColor: string | undefined = globalTextStyles.borderColor
    // Если цвет фона у колонки = none в глобальных параметрах или в параметрах вью, или в параметрах колонки
    // (приориритет следующий, от меньшего к большему: global -> view -> column), то
    // срабатывает #BG_CONDITION (см. комментрарии с этим тегом) и при закреплении колонки
    // администратором или пользователем колонка окрашивается в цвет DEFAULT_BACKGROUND_PINNED_COLUMN (см. #USE_GREY)
    let shouldApplyPinnedBgColor = false

    setGlobalStyles({
      [`.${globalStylesNames.header}`]: globalHeaderStyles,
      [`.${globalStylesNames.text}`]: globalTextStyles,
      [`.${globalStylesNames.pinnedColumnCell}`]: DEFAULT_BACKGROUND_PINNED_COLUMN,
      borderColor,
    })

    let viewHeaderAlign: string | undefined = ''
    let viewAlign: string | undefined = ''

    // #BG_CONDITION
    if (globalTextStyles.background === 'none') {
      shouldApplyPinnedBgColor = true
    }

    if (entity.useLocalParameters && entity.parameters) {
      const { headerProperties: viewHeaderProperties, textProperties: viewTextProperties } =
        getColumnParametersFromArray(entity.parameters)

      if (viewHeaderProperties && viewTextProperties) {
        const { transformedParams: viewColumnHeaderParams, styles: viewHeaderStyles } =
          getColumnParameterStyles(viewHeaderProperties)
        const { transformedParams: viewColumnTextParams, styles: viewTextStyles } =
          getColumnParameterStyles(viewTextProperties)

        viewHeaderAlign = viewColumnHeaderParams?.alignment
        viewAlign = viewColumnTextParams?.alignment
        borderColor = viewColumnTextParams?.borderColor

        // #BG_CONDITION
        if (viewTextStyles.background === 'none') {
          shouldApplyPinnedBgColor = true
        } else {
          shouldApplyPinnedBgColor = false
        }

        setGlobalStyles((prev: Record<any, any>) => ({
          ...prev,
          [`.${entityStylesNames.header}`]: viewHeaderStyles,
          [`.${entityStylesNames.text}`]: viewTextStyles,
          [`.${entityStylesNames.pinnedColumnCell}`]: DEFAULT_BACKGROUND_PINNED_COLUMN,
          borderColor,
        }))
      }
    }

    setColumns(prev => {
      const shouldApplyPinnedBgColorBeforeCalculations = shouldApplyPinnedBgColor

      return prev.map(column => {
        const columnParameters = entity?.columns?.find(
          entityColumn =>
            getColumnFieldName(entityColumn) === column.field && entityColumn.useLocalParameters
        )?.parameters

        const entityColumnData = entity?.columns?.find(
          entityColumn => getColumnFieldName(entityColumn) === column.field
        )

        // TODO: необходимо заменить '.' на '-', т.к. не применяются стили
        let headerAlign: string | undefined = 'left'
        let align: string | undefined = 'left'

        if (globalColumnHeaderParams && globalColumnTextParams) {
          headerAlign = globalColumnHeaderParams?.alignment
          align = globalColumnTextParams?.alignment
        }

        if (entity.useLocalParameters && entity.parameters) {
          headerAlign = viewHeaderAlign
          align = viewAlign
        }

        const columnStylesNames = getClassNameGlobalStyles(
          NAMES_OF_ENTITIES_FOR_STYLES.COLUMN,
          entity,
          type,
          column
        )

        let pinnedColumnCellClassName = columnStylesNames.pinnedColumnCell

        if (columnParameters && columnParameters.length > 0) {
          const { headerProperties: columnHeaderProperties, textProperties: columnTextProperties } =
            getColumnParametersFromArray(columnParameters)

          if (columnHeaderProperties && columnTextProperties) {
            const { transformedParams: columnHeaderParams, styles: columnHeaderStyles } =
              getColumnParameterStyles(columnHeaderProperties)
            const { transformedParams: columnTextParams, styles: columnTextStyles } =
              getColumnParameterStyles(columnTextProperties)

            if (columnHeaderParams && columnTextParams) {
              headerAlign = columnHeaderParams?.alignment
              align = columnTextParams?.alignment
            }

            // #BG_CONDITION
            if (columnTextStyles.background === 'none') {
              shouldApplyPinnedBgColor = true
            } else {
              pinnedColumnCellClassName = ''
              shouldApplyPinnedBgColor = false
            }

            setGlobalStyles((prev: Record<any, any>) => ({
              ...prev,
              [`.${columnStylesNames.header}`]: columnHeaderStyles,
              [`.${columnStylesNames.text}`]: columnTextStyles,
            }))
          }
        }

        const columnIsPinned =
          entityColumnData?.pinnedColumn ||
          entityRestrictions?.fix?.find(
            restriction => restriction.fieldCode === entityColumnData?.code
          )

        // #USE_GREY
        if (shouldApplyPinnedBgColor && columnIsPinned) {
          setGlobalStyles((prev: Record<any, any>) => ({
            ...prev,
            [`.${columnStylesNames.pinnedColumnCell}`]: DEFAULT_BACKGROUND_PINNED_COLUMN,
          }))
        }

        shouldApplyPinnedBgColor = shouldApplyPinnedBgColorBeforeCalculations

        const getClassNameCell = (params?: GridCellParams) => {
          let className = ''

          const checkColumnCodeAndObjectState = entity.columns?.map(
            column => column.code === params?.field && column.useObjectState && column.objectState
          )

          if (cellsObjectStateValue && checkColumnCodeAndObjectState) {
            cellsObjectStateValue.map(cell => {
              if (
                cell.columnCode === params?.field &&
                cell.objectStateValue === `${params?.value}`
              ) {
                return (className = cell.className ?? '')
              }
            })
          }

          return `${globalStylesNames.text} ${entityStylesNames.text} ${pinnedColumnCellClassName} ${columnStylesNames.text} ${className}`
        }

        return {
          ...column,
          headerClassName: `${globalStylesNames.header} ${entityStylesNames.header} ${columnStylesNames.header} ${DEFAULT_HEADER_STYLES_CLASSNAME}`,
          cellClassName: getClassNameCell,
          headerAlign,
          align,
        }
      })
    })
  }, [entity, entityRestrictions, globalColumnHeaderStyles, globalColumnTextStyles])

  useEffect(() => {
    if (entity && !isLoadingView && apiRef.current && apiRef.current.subscribeEvent) {
      apiRef.current.subscribeEvent('cellFocusIn', params => {
        handleSetSelectedRowIds(params.row._id)
      })
    }
  }, [apiRef])

  const isForm = pathname.includes('/forms/')
  const isSearchResult = isAssistiantSearch && entityId
  const isShowTableData = Boolean(entityId)

  useEffect(() => {
    const pinnedColumns: GridPinnedColumns = {
      left: type === ENTITY.DROP_DOWN_ENTITY ? [' ', 'select'] : [' '], // ' ' - стрелка в 1 столбце, обязательное закрепление
      right: [],
    }

    entity?.columns?.forEach(column => {
      const pinnedColumn = column?.pinnedColumn

      if (pinnedColumn) {
        pinnedColumns[pinnedColumn]?.push(column.code)
      }
    })

    if (entityRestrictions && entityRestrictions.fix) {
      const transformedFixRestrictions = transformFixRestrictions(entityRestrictions.fix)

      Object.keys(pinnedColumns).forEach((leftRightKey: string) => {
        pinnedColumns[leftRightKey as GridPinnedPosition]?.push(
          ...transformedFixRestrictions[leftRightKey]
        )
      })
    }

    pinnedColumns.right?.push('actions')

    handleSetPinnedColumns(pinnedColumns)
  }, [entityRestrictions, entity])

  const rowClickAction = findActionByEventCode(EVENT_CODE.ON_ROW_CLICK, actions)
  const rowCreateAction = findActionByEventCode(EVENT_CODE.ON_ROW_CREATE, actions)
  const rowEditAction = findActionByEventCode(EVENT_CODE.ON_ROW_EDIT, actions)
  const rowAdditionalButtonClickAction = findActionByEventCode(
    EVENT_CODE.ON_ROW_ADDITIONAL_BUTTON_CLICK,
    actions
  )

  const hasRowAction = Boolean(getActionBoolean(rowClickAction))

  const showEdit = Boolean(getActionBoolean(rowEditAction))
  const showAdditionalButton = Boolean(getActionBoolean(rowAdditionalButtonClickAction))
  const showActionsColumn = showEdit || showAdditionalButton

  const shouldDisplayCreateButton = getActionBoolean(rowCreateAction)

  const columnsWithActions = useMemo(() => {
    if (showActionsColumn) {
      return [
        generateDataGridLinkColumn(isViewDialogWindow || isListControlDialogWindow),
        ...columns,
        generateDataGridActionsColumn({
          onEdit: showEdit ? handleEdit : undefined,
          onAdditionalButtonClick: showAdditionalButton ? handleAdditionalButtonClick : undefined,
        }),
      ]
    }

    if (hasRowAction) {
      return [
        generateDataGridLinkColumn(isViewDialogWindow || isListControlDialogWindow),
        ...columns,
      ]
    }

    return [...columns]
  }, [columns, parentFormIsDirty, showActionsColumn, handleOpenForm])

  const columnsForEntity = useMemo(
    () =>
      type === ENTITY.DROP_DOWN_ENTITY
        ? [selectionColumn, ...columnsWithActions]
        : columnsWithActions,
    [selectionColumn, type, areAllColumnsHidden]
  )

  const isListControl = type === ENTITY.LIST_CONTROL

  const isColumnReorderEnabled = type === ENTITY.VIEW || type === ENTITY.LIST_CONTROL

  return {
    state: {
      ...state,
      globalStyles,
      isListControl,
      columnVisibilityModel,
      showActionsColumn,
      visibleColumns,
      viewColumns,
      entity,
      configActions,
      position,
      contextMenuRowData,
      objectStyles,
    },
    data: {
      ...data,
      areAllColumnsHidden,
      isAssistiantSearch,
      objectData: areAllColumnsHidden ? [] : data.objectData,
      objects,
      form,
      formIsSuccess,
      actions,
      objectCode,
      allViews,
      columns: columnsForEntity,
      isForm,
      isSearchResult,
      isShowTableData,
      rowCreateAction,
      hasRowAction,
      shouldDisplayCreateButton,
      isLoadingView,
      apiRef,
      isColumnReorderEnabled,
      hasQuickSearch,
    },
    handlers: {
      onCloseContextMenu,
      handleContextMenu,
      ...handlers,
    },
  }
}
