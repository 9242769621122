import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { AutocompleteOption } from '@types'

import { ModalRefPathPicker } from '@gantt/components/GanttCreateOrEdit/components/ModalRefPathPicker'
import { ConfigField } from '@gantt/components/GanttCreateOrEdit/types'
import { OptionsFilter } from '@gantt/types'

type Props = {
  isFlatOptions: boolean
  isDisabled: boolean
  name: string
  fieldName: string
  prefix?: string
  embeddedObjectPickerControllerName: string
  label: string
  index: number
  field: Record<'id', string>
  watchedObject?: AutocompleteOption<string> | null
  onRemove: () => void
  watchValue: ConfigField
  optionsFilter?: OptionsFilter
}

export const PickerItem = ({
  field,
  isDisabled,
  name,
  fieldName,
  prefix,
  isFlatOptions,
  embeddedObjectPickerControllerName,
  index,
  label,
  watchedObject,
  onRemove,
  watchValue,
  optionsFilter,
}: Props) => {
  const [initialValue, setInitialValue] = useState<ConfigField>()

  useEffect(() => {
    if (!initialValue?.field) {
      const clonedValue = structuredClone(watchValue)
      setInitialValue(clonedValue)
    }

    return () => setInitialValue(undefined)
  }, [watchValue?.field])

  const handleRemove = () => {
    onRemove()
  }

  return (
    <Box display={'flex'}>
      <ModalRefPathPicker
        hasField
        currentValue={initialValue}
        embeddedObjectPickerControllerName={embeddedObjectPickerControllerName}
        fieldName={fieldName}
        isDisabled={isDisabled}
        isFlatOptions={isFlatOptions}
        label={index === 0 ? label : ' '}
        name={name}
        optionsFilter={optionsFilter}
        prefix={prefix}
        sx={{ width: '100%' }}
        watchedObject={watchedObject}
        onRemove={handleRemove}
      />
    </Box>
  )
}
