import i18next from 'i18next'
import * as yup from 'yup'

import {
  GanttColumnForm,
  GanttFormValues,
  SectionType,
  TimelineItem,
} from '@gantt/components/GanttCreateOrEdit/types'

export const ganttColumnFormSchema = yup
  .object()
  .shape<Partial<Record<keyof GanttColumnForm, yup.AnySchema>>>({
    code: yup.string().test('required', '', val => Boolean(val?.trim())),
    title: yup.string().test('required', '', val => Boolean(val?.trim())),
    field: yup.object().shape({
      field: yup.object().shape({
        pathStr: yup.string().test('required', '', val => Boolean(val?.trim())),
      }),
    }),
  })

export const resourceFormSchema = yup.object().shape({
  key: yup.object().shape({
    field: yup.object().shape({
      pathStr: yup.string().test('required', '', val => Boolean(val?.trim())),
    }),
  }),
  data: yup.object().shape({
    id: yup.string().test('required', '', val => Boolean(val?.trim())),
    label: yup.string().test('required', '', val => Boolean(val?.trim())),
  }),
  commands: yup.array().of(
    yup.object().shape({
      type: yup.object().shape({
        id: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
        label: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
      }),
    })
  ),
  columns: yup
    .array()
    .of(ganttColumnFormSchema)
    .min(1, i18next.t('error.ganttConfiguration.minColumnsError')),
})

export const sectionFormSchema = yup
  .object()
  .shape<Partial<Record<keyof SectionType, yup.AnySchema>>>({
    link: yup.object().shape({
      axisY: yup.object().shape({
        field: yup.object().shape({
          pathStr: yup.string().test('required', '', val => Boolean(val?.trim())),
        }),
      }),
      axisX: yup.object().shape({
        field: yup.object().shape({
          pathStr: yup.string().test('required', '', val => Boolean(val?.trim())),
        }),
      }),
      systemName: yup.string().test('required', '', val => Boolean(val?.trim())),
    }),
    datetimeStart: yup.object().shape({
      field: yup.object().shape({
        pathStr: yup.string().test('required', '', val => Boolean(val?.trim())),
      }),
    }),
    datetimeEnd: yup.object().shape({
      field: yup.object().shape({
        pathStr: yup.string().test('required', '', val => Boolean(val?.trim())),
      }),
    }),
  })

export const timelineFormSchema = yup
  .object()
  .shape<Partial<Record<keyof TimelineItem, yup.AnySchema>>>({
    key: yup.object().shape({
      field: yup.object().shape({
        pathStr: yup.string().test('required', '', val => Boolean(val?.trim())),
      }),
    }),
    data: yup.object().shape({
      id: yup.string().test('required', '', val => Boolean(val?.trim())),
      label: yup.string().test('required', '', val => Boolean(val?.trim())),
    }),
    commands: yup.array().of(
      yup.object().shape({
        type: yup.object().shape({
          id: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
          label: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
        }),
      })
    ),
    sections: yup
      .array()
      .of(sectionFormSchema)
      .min(1, i18next.t('error.ganttConfiguration.minSegmentsError')),
  })

export const ganttConfigurationSchema = yup
  .object()
  .shape<Partial<Record<keyof GanttFormValues, yup.AnySchema>>>({
    code: yup.string().test('required', '', val => Boolean(val?.trim())),
    title: yup.string().test('required', '', val => Boolean(val?.trim())),
    resource: resourceFormSchema,
    timeline: yup
      .array()
      .of(timelineFormSchema)
      .min(1, i18next.t('error.ganttConfiguration.minBarsError')),
    sort: yup.object().shape({
      code: yup.string().test('required', '', val => Boolean(val?.trim())),
    }),
  })
