import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { Box, Skeleton, Typography } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { useAppSelector } from '@hooks'
import { DRAWER_WIDTH } from '@constants'

const getTime = () => moment().format('DD.MM.YYYY HH:mm')

interface IProps {
  hasSidebar: boolean
  isAdmin: boolean
}

function dispatchEvent(type: string) {
  window.dispatchEvent(new CustomEvent<any>(type))
}

export const AppFooter = (props: IProps) => {
  const { title, leftEvents } = useAppSelector(state => state.appFooter)

  const [now, setNow] = useState<string>()
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    !isLoading && setInterval(() => setNow(getTime()), 60_000)
  }, [isLoading])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setNow(getTime())
    }, 500) // для установки кастомной таймзоны в роуте
  }, [])

  return (
    <Box
      alignItems='center'
      bgcolor={theme => theme.palette.background.footer}
      display='flex'
      gap={2}
      height={30}
      justifyContent='center'
    >
      <Box width={props.hasSidebar ? DRAWER_WIDTH : 0} />
      <Box key='app-footer-title' display='flex' justifyContent='start'>
        <Typography variant='subtitle1'>{title}</Typography>
      </Box>
      {!isEmpty(leftEvents) && (
        <Box key='app-footer-left-events'>
          {leftEvents.map(event => (
            <CustomSvgIcon
              key={event.src + event.title}
              src={event.src}
              sx={{ width: '27px', cursor: 'pointer' }}
              title={event.title}
              onClick={() => dispatchEvent(event.type)}
            />
          ))}
        </Box>
      )}
      <Box flex={1} />
      <Box key='app-footer-datetime' display='flex' justifyContent='end'>
        {isLoading && <Skeleton height={20} variant='rounded' width={150} />}
        {!isLoading && <span title={moment().format('Z')}>{now}</span>}
      </Box>
    </Box>
  )
}
