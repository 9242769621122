import { ROUTES } from '@constants'
import { DIALOG_WINDOW_TYPE } from '@types'

export const openConfigInNewTabBrowser = (
  metaFormAndView?: any,
  codeDDW?: string,
  typeDialog?: DIALOG_WINDOW_TYPE
): void => {
  if (codeDDW) {
    window.open(`${ROUTES.CONFIG_DROP_DOWN_ENTITY_EDIT}/${codeDDW}`, '_blank')
  }
  if (metaFormAndView) {
    if (typeDialog === DIALOG_WINDOW_TYPE.FORM && metaFormAndView.formCode) {
      window.open(`${ROUTES.CONFIG_FORMS_EDIT}/${metaFormAndView.formCode}`, '_blank')
    }
    if (typeDialog === DIALOG_WINDOW_TYPE.VIEW && metaFormAndView.viewCode) {
      window.open(`${ROUTES.CONFIG_VIEWS_EDIT}/${metaFormAndView.viewCode}`, '_blank')
    }
  }
}
