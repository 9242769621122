import {
  ChangeEvent,
  FC,
  FocusEventHandler,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import arrowRightIcon from '@assets/images/arrow-right.svg'
import {
  AutoCompleteInfiniteScroll,
  FormLabel,
  TextField,
} from '@microservices/wiskey-react-components'
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputProps,
  Paper,
  PaperProps,
  Skeleton,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import {
  useFetchListControlOrDropdownObjectDataEnrichedMutation,
  useFetchObjectByCodeQuery,
  useFetchObjectDataRecordFormDropDownDefaultByFieldsMutation,
} from '@redux/api'

import { SortType, useAuth, useInfiniteScroll, useKeyPress } from '@hooks'
import {
  generalFieldStylesByMode,
  getAutoCompletePopperSxByDisplayMethod,
  getFilterByValueType,
  getInitialPositionWindow,
  getLocalStorageContext,
  isNotEmpty,
} from '@helpers'
import {
  DROPDOWN_FILTER_TYPES,
  DROPDOWN_LIST_CLASSNAME,
  FIELD_VALUE_TYPE,
  FORM_CONTAINER_TAB_ID,
  FORM_TYPE,
  HOT_KEY,
  MIN_AUTOCOMPLETE_POPPER_WIDTH,
  UPDATES_ROW_CLASSNAME,
  USER_ROLES,
} from '@constants'
import {
  AutocompleteOption,
  EntityType,
  FieldObjectParams,
  GETEnrichedFilteredEntityObjectDataParams,
  GETObjectDataParamsEnriched,
  LabelPlacement,
  NewObjectDataLinkType,
  ObjectDataRecord,
} from '@types'

import { DropDownList } from '../DropDownList'
import { DropDownWindow } from '../DropDownWindow'

export type ObjectPickerProps = {
  valueType: FIELD_VALUE_TYPE
  isDropDownList?: boolean
  isDropDownWindow?: boolean
  dropDownList?: EntityType
  dropDownWindow?: EntityType
  value?: AutocompleteOption | null | string | AutocompleteOption[]
  dataValue?: string
  onChange?: (details: AutocompleteOption | AutocompleteOption[] | null) => void
  invalid?: boolean
  label?: string
  labelPlacement?: LabelPlacement
  labelSx?: SxProps
  placeholder?: string
  name: string
  size?: 'small' | 'medium'
  objectCode?: string
  readOnly?: boolean
  objectValue?: string
  objectFormCode?: string
  isOptionEqualToValue?:
    | ((option: AutocompleteOption, value: AutocompleteOption) => boolean)
    | undefined
  objectFormTitle?: string
  getValue?: (value: NewObjectDataLinkType, event: MouseEvent<HTMLButtonElement>) => void
  // onLoadObject: (object: ObjectDataRecord, objectModel: GETObjectModel) => void
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disableClearable?: boolean
  disabled?: boolean
  className?: string
  onValidateInputValue?: (value: string) => boolean
  onChangeInput?: (value: string) => void
  objectFormCodeparams?: FieldObjectParams | string
  getFormCodeParams?: (value: FieldObjectParams, objectId: string) => void
  onObjectNotFoundError?: (value: boolean) => void
  onChangeInDropdownEntityMode?: (objectId: string) => void
  inputProps?: InputProps
  multiple?: boolean
  isSearchAssistiant?: boolean
  onChangeMultipleValueDropdownEntity?: (value: AutocompleteOption[]) => void
  dirtyFields?: Partial<Readonly<{ [x: string]: boolean }>>
  formObjectId?: string
  formObjectCode?: string
  formElementId?: number
  valueId?: number
  fieldId?: number
  tabId?: number
  formType?: FORM_TYPE
  isViewEdit?: boolean
  popupIcon?: ReactNode
  sx?: SxProps<Theme>
  isDialogWindow?: boolean
  isDropdownWindowOpenFormState?: boolean
  handleSetDropdownWindowOpenFormState?: (value: boolean) => void
  parentDialogId?: string
  // Нужно для загрузки объекта, если в компонент передали лишь один id: string
  formId?: number
}

export const ObjectPicker: FC<ObjectPickerProps> = ({
  valueType,
  isDropDownList = false,
  isDropDownWindow = false,
  dropDownList,
  dropDownWindow,
  name,
  value,
  dataValue,
  onChange,
  invalid,
  label,
  labelPlacement = 'start',
  labelSx,
  readOnly,
  placeholder,
  size = 'small',
  objectCode = '',
  objectValue = '',
  objectFormCode = '',
  isOptionEqualToValue = (value, option) => value.id === option.id,
  objectFormTitle = '',
  getValue,
  // onLoadObject,
  onBlur,
  disableClearable = true,
  disabled,
  className,
  onValidateInputValue,
  onChangeInput,
  getFormCodeParams,
  objectFormCodeparams,
  onObjectNotFoundError,
  inputProps,
  onChangeInDropdownEntityMode,
  multiple,
  isSearchAssistiant,
  onChangeMultipleValueDropdownEntity,
  dirtyFields,
  formObjectId,
  formObjectCode,
  formElementId,
  valueId,
  fieldId,
  tabId,
  formType,
  isViewEdit,
  popupIcon,
  sx,
  isDialogWindow,
  isDropdownWindowOpenFormState,
  handleSetDropdownWindowOpenFormState,
  parentDialogId,
  formId,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { hasRole } = useAuth()
  const isAdmin = hasRole([USER_ROLES.ADMIN])
  const tooltipBtnToConfigDDW = t('tooltip.openConfigInNewTabBrowser')
  const isObjectPicker = !isDropDownList && !isDropDownWindow
  const isCreateType = formType === FORM_TYPE.CREATE
  const isEditType =
    formType === FORM_TYPE.VIEW_EDIT ||
    formType === FORM_TYPE.EDIT ||
    isViewEdit ||
    isSearchAssistiant
  const [inputValue, setInputValue] = useState('')
  const [initLoading, setInitLoading] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [isDropDownWindowOpen, setDropDownWindowOpen] = useState(false)

  const [customInitialOffset, setCustomInitialOffset] = useState({ x: 0, y: 0 })

  const [localDropDownWindowValue, setLocalDropDownWindowValue] = useState<string | null>(null)
  const [localMultipleDropDownWindowValue, setLocalMultipleDropDownWindowValue] = useState<
    AutocompleteOption[]
  >(value as AutocompleteOption[])

  const context = getLocalStorageContext()

  useEffect(() => {
    if (!multiple && !dropDownWindow) {
      return
    }

    setLocalMultipleDropDownWindowValue(value as AutocompleteOption[])
  }, [value])

  const isValueString = useMemo(() => Boolean(isString(value) && value), [value])

  const { data: objectModel } = useFetchObjectByCodeQuery(objectCode, {
    skip: Array.isArray(value) ? !isOpen && !value.length : !isOpen && !value,
  })

  // Избавились от необходимости грузить целиком объект, потому что value это всегда option
  // const { data: fetchedData, error } = useFetchObjectDataFormDropDownDefaultByIdQuery(
  //   // { objectCode, objectId: value as string },
  //   { formId, objectField: name, objectId: value as string },
  //   { skip: !isValueString },
  // )
  // useEffect(() => {
  //   if (error && 'status' in error && error.status === 404) {
  //     setInputValue('')
  //     onChange?.(null)
  //     onObjectNotFoundError?.(true)
  //   }
  // }, [error])

  // useEffect(() => {
  //   if (fetchedData) {
  //     if (objectModel) {
  //       onLoadObject(fetchedData, objectModel)
  //     }
  //   }
  // }, [fetchedData])

  // const pkField = useMemo(() => objectModel?.fields.find(field => field.isPk), [objectModel])
  const objLinkField = useMemo(
    () =>
      objectModel?.fields.find(field =>
        valueType === FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK ? field.isInternalId : field.isPk
      ),
    [objectModel?.fields, valueType]
  )

  const fieldValue = useMemo(
    () => objectModel?.fields.find(field => field.name === objectValue),
    [objectModel]
  )

  const sort = useMemo(
    (): SortType[] => (isObjectPicker ? [{ field: objectValue, sort: 'asc' }] : []),
    [objectValue, isObjectPicker]
  )
  const isUpdated = className === UPDATES_ROW_CLASSNAME

  useEffect(() => {
    if (value === null || (Array.isArray(value) && value.length === 0)) {
      setInputValue('')
    }
  }, [value])

  const idForDropDownList = formElementId || valueId || fieldId

  // TODO разобраться с параметрами, какие-то возможно лишние
  const dropdownParamsForRequest: GETEnrichedFilteredEntityObjectDataParams & { skip?: boolean } = {
    formObjectCode,
    dropDownEntityType: DROPDOWN_FILTER_TYPES.LIST,
    viewId: dropDownList?.id,
    body: [],
    skip:
      !dropDownList?.id ||
      !idForDropDownList ||
      !formObjectCode ||
      (!isOpen && !initLoading) ||
      !context,
    valueId,
    fieldId,
    formObjectId,
    contextId: context?.id,
  }

  // const useEntityObjectDataMutation = isDropDownList
  //   ? useFetchListControlOrDropdownObjectDataEnrichedMutation
  //   : useFetchObjectDataByCodeMutation

  const useEntityObjectDataMutation = isDropDownList
    ? useFetchListControlOrDropdownObjectDataEnrichedMutation
    : useFetchObjectDataRecordFormDropDownDefaultByFieldsMutation
  // useFetchObjectDataRecordFormDropDownDefaultMutation

  const entityRequestParams = isDropDownList
    ? dropdownParamsForRequest
    : {
        objectCode,
        fields: [objLinkField?.name, objectValue],
        skip: !isOpen && !initLoading,
      }

  useEffect(() => {
    document
      .getElementById(`${FORM_CONTAINER_TAB_ID}_${tabId}`)
      ?.addEventListener('scroll', handleDropDownClose)

    return () => {
      document
        .getElementById(`${FORM_CONTAINER_TAB_ID}_${tabId}`)
        ?.removeEventListener('scroll', handleDropDownClose)
    }
  }, [])

  const {
    combinedData,
    readMore,
    refresh,
    onSetParams,
    currentPage,
    totalPages,
    isLoading: isLoadingData,
  } = useInfiniteScroll<ObjectDataRecord, GETObjectDataParamsEnriched>(
    useEntityObjectDataMutation,
    entityRequestParams,
    undefined,
    { sort }
  )

  // Избавились от необходимости грузить целиком объект, потому что value это всегда option
  // useEffect(() => {
  //   if (fieldValue && isNotEmpty(dataValue) && objectValue !== '_id') {
  //     onSetParams({
  //       page: 0,
  //       filter: getFilterByValueType(objectValue, dataValue!, fieldValue?.valueType),
  //       sort,
  //     })
  //   }
  // }, [dataValue, fieldValue])

  const handleResetFilter = () =>
    onSetParams({
      page: 0,
      filter: undefined,
      sort,
    })

  useEffect(() => {
    if (!Array.isArray(value) && value && isObject(value)) {
      setInputValue(value.label)
    }
  }, [value])

  const options: AutocompleteOption[] = useMemo(
    () =>
      combinedData?.map(item => ({
        id: objLinkField ? (item[objLinkField.name] as string | number) : '',
        label: isNotEmpty(item[objectValue]) ? `${item[objectValue]}` : '',
      })) ?? [],
    [combinedData, objLinkField]
  )

  const loadNextPage = () => {
    if (totalPages !== currentPage) {
      readMore()
    }
  }

  const isLoading = value && isString(value) && !isCreateType

  const renderPaperComponent = useCallback((props: PaperProps) => {
    return (
      <Paper
        {...props}
        sx={{
          minWidth: MIN_AUTOCOMPLETE_POPPER_WIDTH,
          overflowX: 'scroll',
        }}
      />
    )
  }, [])

  const handleDropDownOpen = (event: SyntheticEvent<Element, Event>) => {
    const eventTarget = event.target as HTMLElement

    if (initLoading) {
      setInitLoading(true)
    }

    if (readOnly && (eventTarget.tagName === 'INPUT' || eventTarget.tagName === 'DIV')) {
      return
    }

    setOpen(true)
  }

  const handleDropDownClose = () => {
    setOpen(false)
  }

  const handleChangeAutocomplete = (details: AutocompleteOption | null | AutocompleteOption[]) => {
    if (readOnly) {
      return
    }
    onChange?.(details)

    // делать сброc, только когда был поиск по значению
    if (inputValue && multiple) {
      refresh({ filter: undefined })
    }

    setInputValue('')
  }

  const renderInput = () => {
    if (isLoading) {
      return (
        <Skeleton
          sx={{ width: '100%', height: isDialogWindow ? 25 : 40, borderRadius: '4px' }}
          variant={'rectangular'}
        />
      )
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (readOnly) {
        return
      }

      const value = e.target.value.trim()

      if (!onValidateInputValue?.(value)) {
        return
      }

      onChangeInput?.(value)
      if (value === inputValue) {
        return
      }
      setInputValue(value)
      onSetParams({
        page: 0,
        filter: e.target.value
          ? getFilterByValueType(
              objectValue,
              e.target.value,
              fieldValue?.valueType || FIELD_VALUE_TYPE.STRING
            )
          : undefined,
        sort,
      })
    }

    const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
      onBlur?.(e)
      if (value) {
        return
      }
      handleResetFilter()
      setInputValue('')
    }

    const handleClearIcon = () => {
      handleResetFilter()
      setInputValue('')
      onChange?.(null)
    }

    const isLinkedObject =
      objectFormCode && value && isObject(value) && !Array.isArray(value) && value.id

    const handleOpenForm = (event: MouseEvent<HTMLButtonElement>) => {
      if (!Array.isArray(value)) {
        if (isLinkedObject) {
          getValue?.(
            {
              objectId: `${value.id}`,
              objectCode,
              formCode: objectFormCode,
              title: objectFormTitle,
              isShiftKey: event.shiftKey,
            },
            event
          )

          return
        }
        if (objectFormCodeparams && isObject(objectFormCodeparams) && value && isObject(value)) {
          getFormCodeParams?.(objectFormCodeparams, `${value.id}`)
        }
      }
    }

    const handleDoubleClick: MouseEventHandler<HTMLDivElement> = e => {
      // setCustomInitialOffset({ x: 300, y: -(document.documentElement.scrollHeight - e.pageY) })
      const initialPosition = getInitialPositionWindow(e)
      setCustomInitialOffset(initialPosition)
      setOpen(false)

      if (isDropDownWindow) {
        setDropDownWindowOpen(true)
        handleSetDropdownWindowOpenFormState?.(true)
      }
    }

    return (
      <AutoCompleteInfiniteScroll
        fullWidth
        PaperComponent={renderPaperComponent}
        className={className}
        disableClearable={disableClearable}
        disableCloseOnSelect={multiple}
        getOptionLabel={option => (isObject(option) ? option.label : '')}
        inputValue={inputValue}
        isOptionEqualToValue={isOptionEqualToValue}
        loading={isLoadingData}
        multiple={multiple}
        open={isOpen}
        options={options}
        page={currentPage}
        popupIcon={popupIcon}
        size={size}
        sx={sx}
        totalPages={totalPages}
        value={value}
        componentsProps={{
          clearIndicator: {
            onClick: handleClearIcon,
          },
          popper: {
            className: isDropDownList ? DROPDOWN_LIST_CLASSNAME : undefined,
            sx: {
              zIndex: theme => theme.zIndex.tooltip,
              ...getAutoCompletePopperSxByDisplayMethod(isDialogWindow, readOnly, theme),
              border: isDropDownList ? `1px solid #303030` : 'none',
              borderTop: 'none',
              borderBottom: 'none',
              boxSizing: 'content-box',
            },
          },
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              error={invalid}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {(isLinkedObject || objectFormCodeparams) && (
                      <InputAdornment
                        position='end'
                        sx={{
                          position: 'absolute',
                          right: 20,
                          bottom: isDialogWindow ? 12 : 20,
                          zIndex: theme => theme.zIndex.modal,
                        }}
                      >
                        <IconButton
                          sx={{ mr: '7px', padding: isDialogWindow ? 0 : 1 }}
                          title={
                            objectFormTitle
                              ? t('objectPicker.openForm', { formTitle: objectFormTitle })
                              : undefined
                          }
                          onClick={handleOpenForm}
                        >
                          <CustomSvgIcon
                            fontSize={isDialogWindow ? 'small' : 'medium'}
                            src={arrowRightIcon}
                          />
                        </IconButton>
                      </InputAdornment>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
                startAdornment: (
                  <>
                    {inputProps?.startAdornment}
                    {params.InputProps.startAdornment}
                  </>
                ),
                ...inputProps?.inputProps,
              }}
              sx={theme => ({
                ...generalFieldStylesByMode(theme, dirtyFields, name, isUpdated, isDialogWindow),
                '.MuiInputBase-root': {
                  pr: '57px !important',
                  '& .MuiChip-root': {
                    height: isSearchAssistiant ? '19px' : '23px',
                  },
                },
                '& .MuiAutocomplete-endAdornment': {
                  right: '0px !important',
                },
                '.MuiOutlinedInput-root': {
                  backgroundColor: readOnly
                    ? theme.palette.background.hovered
                    : theme.palette.background.paper,
                },
              })}
              onBlur={handleBlur}
              onChange={handleChange}
              onDoubleClick={handleDoubleClick}
            />
          )
        }}
        renderOption={(props, option) => {
          return isDropDownList ? (
            <DropDownList
              combinedData={combinedData}
              dropDownList={dropDownList}
              option={option}
              pkFieldName={objLinkField?.name}
              props={props}
            />
          ) : (
            <li {...props} key={option.id} style={{ paddingRight: 0 }} title={option.label}>
              <Box sx={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {option.label}
              </Box>
            </li>
          )
        }}
        onChange={handleChangeAutocomplete}
        onClose={handleDropDownClose}
        onLoadNextPage={loadNextPage}
        onOpen={handleDropDownOpen}
      />
    )
  }

  const drowdownWindowValue = Array.isArray(value)
    ? { multipleSelectPickerOption: localMultipleDropDownWindowValue }
    : { selectedPickerOptionValue: value ? value : '' }

  const handleLocalDropDownValueChange = (objectId: string) => {
    setLocalDropDownWindowValue(objectId)
  }

  const handleLocalMultipleDropDownValueChange = (value: AutocompleteOption[]) => {
    setLocalMultipleDropDownWindowValue(value)
  }

  const handleDropDownWindowClose = ({
    shouldApplyValueToForm,
  }: {
    shouldApplyValueToForm: boolean
  }) => {
    if (localDropDownWindowValue && shouldApplyValueToForm) {
      onChangeInDropdownEntityMode?.(localDropDownWindowValue)
    }

    if (localMultipleDropDownWindowValue && shouldApplyValueToForm) {
      onChangeMultipleValueDropdownEntity?.(localMultipleDropDownWindowValue)
    }

    if (localMultipleDropDownWindowValue && !shouldApplyValueToForm) {
      setLocalMultipleDropDownWindowValue(value as AutocompleteOption[])
    }

    setDropDownWindowOpen(false)
    setTimeout(() => {
      handleSetDropdownWindowOpenFormState?.(false)
    }, 0)
  }

  const { keyPressed: exitDropdownWindowTrigger, setKeyPressed: setExitDropdownTriggerKeyPressed } =
    useKeyPress(HOT_KEY.ESCAPE, undefined, !isDropDownWindowOpen)

  const { keyPressed: saveDropdownWindowValueTrigger } = useKeyPress(
    HOT_KEY.ENTER,
    undefined,
    !isDropDownWindowOpen
  )

  useEffect(() => {
    if (saveDropdownWindowValueTrigger) {
      handleDropDownWindowClose({ shouldApplyValueToForm: true })

      return
    }

    if (exitDropdownWindowTrigger && !saveDropdownWindowValueTrigger) {
      handleDropDownWindowClose({ shouldApplyValueToForm: false })
      setExitDropdownTriggerKeyPressed(false)

      return
    }
  }, [saveDropdownWindowValueTrigger, exitDropdownWindowTrigger])

  return (
    <>
      {isDropDownWindowOpen &&
        dropDownWindow &&
        createPortal(
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              // display: 'block',
              // bottom: 0,
              // right: 0,
              // zIndex: theme => theme.zIndex.modal,
            }}
          >
            <DropDownWindow
              customInitialOffset={customInitialOffset}
              dropDownWindow={dropDownWindow}
              elementId={formElementId}
              fieldId={fieldId}
              formObjectCode={formObjectCode}
              formObjectId={formObjectId}
              isAdmin={isAdmin}
              isDialogWindow={isDialogWindow}
              objectValueName={objectValue}
              parentDialogId={parentDialogId}
              readonly={readOnly}
              theme={theme}
              tooltipBtnToConfig={tooltipBtnToConfigDDW}
              onMultiSelectPickerOption={handleLocalMultipleDropDownValueChange}
              // onSelectPickerOption={onChangeInDropdownEntityMode}
              // onMultiSelectPickerOption={onChangeMultipleValueDropdownEntity}
              valueId={valueId}
              onClose={handleDropDownWindowClose}
              onSelectPickerOption={handleLocalDropDownValueChange}
              {...drowdownWindowValue}
            />
          </Box>,
          document.body
        )}
      <Grid container item justifyContent={'space-between'}>
        <FormLabel
          control={renderInput()}
          label={label}
          labelPlacement={labelPlacement}
          labelSx={labelSx}
          name={name}
        />
      </Grid>
    </>
  )
}
