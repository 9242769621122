import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

import { useFetchContextGanttChartsByIdQuery, useFetchContextViewsByIdQuery } from '@redux/api'
import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'

import { getLocalStorageContext, getOptionRoute } from '@helpers'
import {
  COMMON_PARAMETERS,
  COMMON_PARAMETERS_DEFAULT_ENTITY,
  GLOBAL_PARAMETER_CODE,
} from '@constants'

import {
  transformCommonParameters,
  transformCommonParametersData,
  transformTextParamsData,
} from '../helpers'

import { useHandlers } from './useHandlers'

export type CommonParametersFormType = {
  numberOfWindows: AutocompleteOption | null
  timezone: AutocompleteOption | null
  pagination: AutocompleteOption | null
  backgroundEntityOption: COMMON_PARAMETERS_DEFAULT_ENTITY | null
  ganttChart: AutocompleteOption | null
  view: AutocompleteOption | null
}

export const useParameters = () => {
  const methods = useForm<CommonParametersFormType>({
    defaultValues: {
      numberOfWindows: null,
      pagination: null,
      timezone: null,
      backgroundEntityOption: null,
      ganttChart: null,
      view: null,
    },
  })
  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = methods

  const { data: globalColumnHeaderStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_HEADER_STYLES,
  })

  const { data: globalColumnTextStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_TEXT_STYLES,
  })

  const { data: commonParametersData } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.DATA,
  })

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const context = getLocalStorageContext()

  const { data: contextViews } = useFetchContextViewsByIdQuery(context?.id, {
    skip: !context,
  })

  const { data: contextGanttCharts } = useFetchContextGanttChartsByIdQuery(context?.id, {
    skip: !context,
  })

  const initialGlobalColumnHeaderStyles = useMemo(
    () => transformTextParamsData(globalColumnHeaderStyles),
    [globalColumnHeaderStyles]
  )
  const initialGlobalColumnTextStyles = useMemo(
    () => transformTextParamsData(globalColumnTextStyles),
    [globalColumnTextStyles]
  )

  const initialCommonParameters = useMemo(
    () => transformCommonParameters(commonParameters),
    [commonParameters]
  )

  const ganttChartAutocompleteOptions = useMemo(
    () =>
      contextGanttCharts?.selectedGanttCharts.map(gantt => ({
        id: getOptionRoute(gantt),
        label: gantt.title,
      })) || [],
    [contextGanttCharts]
  )

  const viewAutocompleteOptions = useMemo(
    () =>
      contextViews?.selectedViews.map(view => ({
        id: getOptionRoute(view),
        label: view.title,
      })) || [],
    [contextViews]
  )

  const initialCommonParametersData = useMemo(
    () => ({
      ...transformCommonParametersData(commonParametersData || []),
      ganttChart: ganttChartAutocompleteOptions,
      view: viewAutocompleteOptions,
    }),
    [commonParametersData, ganttChartAutocompleteOptions, viewAutocompleteOptions]
  )

  useEffect(() => {
    if (initialCommonParameters) {
      const ganttChart =
        ganttChartAutocompleteOptions.find(
          ({ id }) => id === initialCommonParameters.ganttChart?.id
        ) || null
      const view =
        viewAutocompleteOptions.find(({ id }) => id === initialCommonParameters.view?.id) || null

      const backgroundEntityOption = view
        ? COMMON_PARAMETERS_DEFAULT_ENTITY.VIEW
        : COMMON_PARAMETERS_DEFAULT_ENTITY.GANTT_CHART

      reset({ ...initialCommonParameters, ganttChart, view, backgroundEntityOption })
    }
  }, [initialCommonParameters, ganttChartAutocompleteOptions, viewAutocompleteOptions])

  const { handlers, state, data } = useHandlers({
    onSubmitCommonForm: handleSubmit,
    commonParameters,
    reset,
    isDirtyCommonParameters: isDirty,
    initialGlobalColumnHeaderStyles,
    initialGlobalColumnTextStyles,
    onSetValueCommonParameters: setValue,
  })

  return {
    data: {
      ...data,
      initialGlobalColumnHeaderStyles,
      initialGlobalColumnTextStyles,
      initialCommonParametersData,
      initialCommonParameters,
    },
    state: {
      ...state,
      methods,
    },
    handlers,
  }
}
