import { useEffect, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { getDefaultValueByType } from '@components/DisplayForm/helpers'
import { useCreateObjectDataRecordWithFixedKey } from '@components/DisplayForm/hooks/useCreateObjectDataRecordWithFixedKey'
import { useFetchObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchObjectDataWithFixedKey'
import { useFetchPreFillObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchPreFillObjectDataWithFixedKey'
import { useFormFields } from '@components/DisplayForm/hooks/useFormFields'
import { useUpdateObjectDataRecordWithFixedKey } from '@components/DisplayForm/hooks/useUpdateObjectDataRecordWithFixedKey'

import { isNotEmpty } from '@helpers'
import { FORM_TYPE, PreFillType } from '@constants'
import { ObjectDataRecord, Row } from '@types'

type UseFormResetLogicParams = {
  methods: UseFormReturn<Row>
  type: FORM_TYPE
  globalId: string
  formCode: string
  preFill?: PreFillType
}

export const useFormResetLogic = ({
  methods: formMethods,
  type,
  globalId,
  formCode,
  preFill,
}: UseFormResetLogicParams) => {
  const { reset, setValue, getValues } = formMethods

  const [, { data: fetchedRow }] = useFetchObjectDataWithFixedKey(globalId)

  const [, { data: preFillFetchedRow }] = useFetchPreFillObjectDataWithFixedKey(globalId)

  const formFields = useFormFields({ formCode })

  const [, { isLoading: isLoadingUpdateRow }] = useUpdateObjectDataRecordWithFixedKey(globalId)

  const [, { isLoading: isLoadingCreateRow }] = useCreateObjectDataRecordWithFixedKey(globalId)
  // TODO Рефакторинг логики с preFill
  const row = useMemo(() => {
    if (type === FORM_TYPE.CREATE) {
      if (preFill) {
        return preFillFetchedRow
      }

      return {}
    }

    return fetchedRow
  }, [type, fetchedRow, preFillFetchedRow])

  const defaultValues: ObjectDataRecord = useMemo(
    () =>
      row
        ? Object.fromEntries(
            formFields.map(field => {
              return getDefaultValueByType(field, row)
            })
          )
        : {},
    [formFields, row]
  )

  useEffect(() => {
    // Форма создания
    if (type === FORM_TYPE.CREATE) {
      if (preFill) {
        // Предзаполняем поля и делаем их dirty
        Object.entries(defaultValues).forEach(([key, value]) => {
          if (isNotEmpty(value)) {
            setValue(key, value, { shouldDirty: true, shouldTouch: true, shouldValidate: true })
          }
        })

        return
      }

      reset(defaultValues)

      return
    }
    // Форма редактирования
    if (defaultValues) {
      // if (defaultValues) {
      // reset({ ...defaultValues, ...row })

      reset({ ...defaultValues })

      return
    }
  }, [
    // row,
    defaultValues,
    type,
  ])

  useEffect(() => {
    if ((!isLoadingUpdateRow || !isLoadingCreateRow) && type !== FORM_TYPE.CREATE) {
      // reset({ ...defaultValues, ...row })

      reset({ ...defaultValues })
    }
  }, [
    // row,
    defaultValues,
    globalId,
  ])

  return { defaultValues }
}
