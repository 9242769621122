import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from 'i18next'

import { FetchFieldMutationType } from '@pages/FormCreateOrEdit/types'

import { BIND_TYPE, FIELD_VALUE_TYPE, MODAL_TYPE, ROUTES } from '@constants'
import { ColumnParametersType, ModalType, MutationType, ShareColumnType } from '@types'

import {
  AutocompleteOption,
  AvailableVariables,
  GanttActionType,
  GanttColumnForm,
  GanttFormValues,
  GanttFormValuesBack,
  SectionType,
} from '@gantt/components/GanttCreateOrEdit'
import { initialAvailableVariables } from '@gantt/constants'

type UseHandlersParams = {
  fetchField: FetchFieldMutationType
  createGantt: MutationType<GanttFormValues, GanttFormValues>
  updateGantt: MutationType<GanttFormValues, GanttFormValues>
}

export type GanttRow = {
  id: string | number
  order?: number
  code: string
  title: string
  valueType: FIELD_VALUE_TYPE | null
  bindType?: BIND_TYPE
  value: string
  objectCode?: string
  objectValue?: string
  editField: boolean
  parameters: ColumnParametersType
}

export const useHandlers = ({ fetchField, createGantt, updateGantt }: UseHandlersParams) => {
  const [modalProperty, setModalProperty] = useState<ShareColumnType>()
  const [objectCodeResource, setObjectCodeResource] = useState<string>()
  const [objectCodeTimelines, setObjectCodeTimelines] = useState<string>()
  const [watchedObjectResource, setWatchedObjectResource] =
    useState<AutocompleteOption<string> | null>(null)
  const [watchedObjectTimeline, setWatchedObjectTimeline] =
    useState<AutocompleteOption<string> | null>(null)
  const [currentRow, setCurrentRow] = useState<
    GanttColumnForm | GanttActionType | SectionType | null
  >()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [modalType, setModalType] = useState<ModalType>('create')
  const [currentColumn, setCurrentColumn] = useState<GanttColumnForm>()
  const [currentActionResource, setCurrentActionResource] = useState<GanttActionType>()
  const [currentActionTimeline, setCurrentActionTimeline] = useState<GanttActionType>()
  const [columnToDelete, setColumnToDelete] = useState<string | number>()
  const [actionResourceToDelete, setActionResourceToDelete] = useState<string | number>()
  const [actionTimelineToDelete, setActionTimelineToDelete] = useState<string | number>()
  const [currentConfiguration, setCurrentConfiguration] = useState<GanttFormValuesBack>()
  const [currentColumnsList, setCurrentColumnsList] = useState<GanttColumnForm[]>([])
  const [currentSectionsList, setCurrentSectionsList] = useState<SectionType[]>([])
  const [currentSegment, setCurrentSegment] = useState<SectionType | undefined>()
  const [currentBarIndex, setCurrentBarIndex] = useState<number>(0)
  const [segmentToDelete, setSegmentToDelete] = useState<string | number>()
  const [currentBarObject, setCurrentBarObject] = useState<AutocompleteOption<string> | null>()
  const [availableVariables, setAvailableVariables] = useState<AvailableVariables>(
    initialAvailableVariables()
  )
  const [currentActionResourceList, setCurrentActionResourceList] = useState<GanttActionType[]>([])
  const [currentActionTimelineList, setCurrentActionTimelineList] = useState<GanttActionType[]>([])

  const { code } = useParams()
  const navigate = useNavigate()

  const handleSetCurrentActionResource = (action: GanttActionType) =>
    setCurrentActionResource(action)

  const handleSetCurrentActionTimeline = (action: GanttActionType) =>
    setCurrentActionTimeline(action)

  const handleSetObjectCodeResource = (value: string) => setObjectCodeResource(value)

  const handleSetObjectCodeTimelines = (value: string) => setObjectCodeTimelines(value)

  const handleSetWatchedObjectResource = (value: AutocompleteOption<string> | null) =>
    setWatchedObjectResource(value)

  const handleSetWatchedObjectTimeline = (value: AutocompleteOption<string> | null) =>
    setWatchedObjectTimeline(value)

  const handleSetCurrentColumn = (column: GanttColumnForm) => {
    setCurrentColumn(column)
  }

  const handleSetCurrentConfiguration = (configuration: GanttFormValuesBack) =>
    setCurrentConfiguration(configuration)

  const handleSetCurrentSegment = (segment: SectionType) => setCurrentSegment(segment)

  const handleSetCurrentBarIndex = (barIndex: number) => setCurrentBarIndex(barIndex)

  const handleSetCurrentBarObject = (barObject: AutocompleteOption<string> | null) =>
    setCurrentBarObject(barObject)

  const handleSetColumnToDelete = (id: number) => setColumnToDelete(id)

  const handleSetModalProperty = (type: ShareColumnType) => setModalProperty(type)

  const handleSetCurrentRow = (
    value: GanttColumnForm | GanttActionType | SectionType | null
  ): void => setCurrentRow(value)

  const handleSetCurrentColumnsList = (value: GanttColumnForm[]) => {
    setCurrentColumnsList(value)
  }

  const handleSetCurrentSectionsList = (value: SectionType[]) => {
    setCurrentSectionsList(value)
  }

  const handleSetCurrentActionResourceList = (value: GanttActionType[]) => {
    setCurrentActionResourceList(value)
  }

  const handleSetCurrentActionTimelineList = (value: GanttActionType[]) => {
    setCurrentActionTimelineList(value)
  }

  const handleCloseModal = (isDirty?: boolean) => {
    if (isDirty && confirm(t('notifications.leave'))) {
      setShowDialog(false)
      currentRow && setCurrentRow(null)

      return
    }
    if (!isDirty) {
      setShowDialog(false)
      currentRow && setCurrentRow(null)
    }
  }

  const handleOpenDialog = async (
    type: ModalType,
    id?: number | string,
    row?: GanttColumnForm | GanttActionType | SectionType | null,
    barIndex?: number | null,
    selectedBarObject?: AutocompleteOption<string> | null,
    inputAvailableVariables?: AvailableVariables
  ) => {
    if (type === MODAL_TYPE.EDIT && id && row) {
      setCurrentRow(row)
    }

    setAvailableVariables(inputAvailableVariables || initialAvailableVariables())
    setModalType(type)
    setCurrentBarIndex(barIndex || 0)
    setCurrentBarObject(selectedBarObject)
    setShowDialog(true)
  }

  const handleEdit = (
    row: GanttColumnForm | GanttActionType | SectionType | null,
    id?: number | string,
    isAction?: boolean,
    barIndex?: number,
    selectedBarObject?: AutocompleteOption<string> | null,
    inputAvailableVariables?: AvailableVariables
  ) => {
    if (id) {
      handleOpenDialog(
        MODAL_TYPE.EDIT,
        id,
        row,
        barIndex,
        selectedBarObject,
        inputAvailableVariables
      )
    }
  }

  const handleHide = (row: GanttColumnForm, checked: boolean) => {
    const newColumn = { ...row, visibility: checked } as GanttColumnForm
    handleSetCurrentColumn(newColumn)
  }

  const handleDeleteColumn = (id: string | number) => {
    setColumnToDelete(id)
  }

  const handleDeleteActionResource = (id: string | number) => {
    setActionResourceToDelete(id)
  }

  const handleDeleteActionTimeline = (id: string | number, barIndex: number) => {
    setCurrentBarIndex(barIndex)
    setActionTimelineToDelete(id)
  }

  const handleDeleteSegment = useCallback(
    (id: string | number, barIndex: number) => {
      setCurrentBarIndex(barIndex)
      setSegmentToDelete(id)
    },
    [setSegmentToDelete]
  )

  const handleAddGantt = (gantt: GanttFormValues) => {
    if (!gantt.id) {
      return createGantt({
        ...gantt,
      })
        .unwrap()
        .then(res => {
          if (!code) {
            navigate(`${ROUTES.CONFIG_GANTT_EDIT}/${res}`)
          }
        })
    } else {
      return updateGantt({
        ...gantt,
      }).unwrap()
    }
  }

  return {
    state: {
      modalType,
      objectCodeResource,
      objectCodeTimelines,
      showDialog,
      modalProperty,
      currentRow,
      watchedObjectResource,
      watchedObjectTimeline,
      currentColumn,
      columnToDelete,
      currentConfiguration,
      currentActionResource,
      currentActionTimeline,
      actionResourceToDelete,
      actionTimelineToDelete,
      currentColumnsList,
      currentSectionsList,
      currentSegment,
      segmentToDelete,
      currentBarIndex,
      currentBarObject,
      availableVariables,
      currentActionResourceList,
      currentActionTimelineList,
    },
    data: {},
    handlers: {
      handleSetObjectCodeResource,
      handleSetObjectCodeTimelines,
      handleCloseModal,
      handleOpenDialog,
      handleSetModalProperty,
      handleSetCurrentRow,
      handleEdit,
      handleAddGantt,
      handleSetWatchedObjectResource,
      handleSetWatchedObjectTimeline,
      handleSetCurrentColumn,
      handleSetColumnToDelete,
      handleDeleteColumn,
      handleSetCurrentConfiguration,
      handleSetCurrentActionResource,
      handleSetCurrentActionTimeline,
      handleDeleteActionResource,
      handleDeleteActionTimeline,
      handleHide,
      handleSetCurrentColumnsList,
      handleSetCurrentSectionsList,
      handleSetCurrentSegment,
      handleDeleteSegment,
      handleSetCurrentBarIndex,
      handleSetCurrentBarObject,
      handleSetCurrentActionResourceList,
      handleSetCurrentActionTimelineList,
    },
  }
}
