import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import i18next from 'i18next'
import i18n from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { FormHelperText } from '@mui/material'

import { CommandPickerController } from '@components/hookFormControllers/CommandPickerController'
import { PickerFieldArrayController } from '@components/hookFormControllers/PickerFieldArrayController'
import { ScriptValueEditor } from '@components/ScriptValueDialog'
import { ColorSettings } from '@components/TextSettings/components/ColorSettings'

import { ErrorValidJSType } from '@helpers'
import { GENERATOR_INPUT_TYPE } from '@constants'

import { ModalRefPathPicker } from '@gantt/components/GanttCreateOrEdit/components/ModalRefPathPicker' //todo не должно находится тут
import {
  AutocompleteOption,
  BIND_TYPE_INPUTS_FORM_TYPE,
  ConfigField,
  GANTT_BIND_TYPE_LIST,
} from '@gantt/components/GanttCreateOrEdit/types'
import { getCommandsFormattedRequest, getKeyByBindType } from '@gantt/helpers'
import { ExtOptionFilter, OptionsFilter } from '@gantt/types'

import 'prismjs/components/prism-json'

type Props = {
  hasCommands?: boolean
  getPath: (key: GANTT_BIND_TYPE_LIST | string) => string
  watchBinding: GANTT_BIND_TYPE_LIST
  watchedObject?: AutocompleteOption<string> | null
  formType: BIND_TYPE_INPUTS_FORM_TYPE
  hint?: string
  inputType?: InputType
  language: string
  valueInputLabel?: string
  isDisabled?: boolean
  prefix?: string
  children?: ReactNode
  placeholder?: string
  noValidate?: boolean
  options?: ExtOptionFilter[]
  validator?: (value: string) => ErrorValidJSType | undefined
  onSaveField?: (value: ConfigField) => void
  onAfterValidationScript?: (state: boolean) => void
  optionsFilter?: OptionsFilter
}

export type InputType = GENERATOR_INPUT_TYPE | INPUT_TYPE

export enum INPUT_TYPE {
  COLOR = 'color',
}

export const ValueInputFactory = ({
  hasCommands,
  getPath,
  watchBinding,
  watchedObject,
  formType = BIND_TYPE_INPUTS_FORM_TYPE.RESOURCE,
  hint,
  inputType = GENERATOR_INPUT_TYPE.INPUT,
  language = 'js',
  valueInputLabel = i18n.t('label.value'),
  isDisabled = false,
  prefix,
  children,
  placeholder,
  noValidate,
  options,
  validator,
  onSaveField,
  onAfterValidationScript,
  optionsFilter,
}: Props) => {
  const { setValue, watch } = useFormContext()
  const watchValue = watch(getPath(getKeyByBindType(watchBinding)))
  const [initialValue, setInitialValue] = useState<ConfigField>()

  // ModalRefPathPicker restore value on cancel
  useEffect(() => {
    if (!initialValue?.field) {
      const clonedValue = structuredClone(watchValue)
      setInitialValue(clonedValue)
    }

    return () => setInitialValue(undefined)
  }, [watchValue?.field])

  const handleChange = useCallback((key: GANTT_BIND_TYPE_LIST, value: string) => {
    setValue(getPath(key), value, { shouldDirty: true })
  }, [])

  const handleOnSaveField = useCallback((value: ConfigField) => {
    hasCommands &&
      setValue(getPath(`field.commands`), getCommandsFormattedRequest(value.commands || []))
    onSaveField?.(value)
  }, [])

  return (
    <>
      {watchBinding === GANTT_BIND_TYPE_LIST.FIELD && (
        <ModalRefPathPicker
          hasField
          commandsName={getPath(`field.commands`)}
          currentValue={initialValue}
          embeddedObjectPickerControllerName={getPath(`field.pathArray`)}
          fieldName={getPath(`field.field`)}
          hasCommands={hasCommands}
          isDisabled={isDisabled}
          label={valueInputLabel}
          name={getPath(`field.pathStr`)}
          optionsFilter={optionsFilter}
          pickerName={getPath('field')}
          prefix={prefix}
          watchedObject={watchedObject}
          onSave={handleOnSaveField}
        />
      )}
      {watchBinding === GANTT_BIND_TYPE_LIST.STATIC && inputType !== INPUT_TYPE.COLOR && (
        <>
          <FormInput
            inputType={inputType}
            label={valueInputLabel}
            name={getPath(GANTT_BIND_TYPE_LIST.STATIC)}
            placeholder={placeholder}
          />
          {hint && <FormHelperText sx={{ pl: 15, ml: 0 }}>{hint}</FormHelperText>}
        </>
      )}
      {watchBinding === GANTT_BIND_TYPE_LIST.STATIC && inputType === INPUT_TYPE.COLOR && (
        <>
          <ColorSettings
            clearableColor
            isEdit
            color={watchValue}
            label={valueInputLabel ?? i18n.t('label.value')}
            labelMaxWidth={120}
            labelPlacement={'left'}
            onChangeColor={value => handleChange(GANTT_BIND_TYPE_LIST.STATIC, value)}
          />
          {hint && <FormHelperText sx={{ pl: 15, ml: 0 }}>{hint}</FormHelperText>}
        </>
      )}
      {watchBinding === GANTT_BIND_TYPE_LIST.JS && (
        <ScriptValueEditor
          hint={hint}
          label={valueInputLabel}
          language={'js'}
          maxInputLength={10000}
          noValidate={noValidate}
          placeholder={placeholder}
          validator={validator}
          value={watchValue}
          onAfterValidationScript={onAfterValidationScript}
          onChange={value => handleChange(GANTT_BIND_TYPE_LIST.JS, value)}
        />
      )}
      {watchBinding === GANTT_BIND_TYPE_LIST.FIELD_ARRAY && (
        <PickerFieldArrayController
          disabled={isDisabled}
          isFlatOptions={false}
          label={valueInputLabel ?? i18next.t('label.value')}
          name={getPath(GANTT_BIND_TYPE_LIST.FIELD_ARRAY)}
          optionsFilter={optionsFilter}
          prefix={prefix}
          watchedObject={watchedObject}
        />
      )}
      {watchBinding === GANTT_BIND_TYPE_LIST.JSON && (
        <ScriptValueEditor
          hint={hint}
          label={valueInputLabel}
          language={'json'}
          maxInputLength={10000}
          placeholder={placeholder}
          validator={validator}
          value={watchValue}
          onAfterValidationScript={onAfterValidationScript}
          onChange={value => handleChange(GANTT_BIND_TYPE_LIST.JSON, value)}
        />
      )}
      {watchBinding === GANTT_BIND_TYPE_LIST.COMMANDS && (
        <CommandPickerController
          commandName={'name'}
          hasAddButton={false}
          name={getPath(GANTT_BIND_TYPE_LIST.COMMANDS)}
          objectCode={watchedObject?.id || ''}
        />
      )}
      {watchBinding === GANTT_BIND_TYPE_LIST.FORM && (
        <FormInput
          autocompleteOptions={options as AutocompleteOption[]}
          inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
          label={valueInputLabel}
          name={getPath(GANTT_BIND_TYPE_LIST.FORM)}
          placeholder={placeholder}
          rules={{ required: true }}
        />
      )}
      {watchBinding === GANTT_BIND_TYPE_LIST.CUSTOM && !!children && children}
    </>
  )
}
