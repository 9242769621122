import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithNotifications } from './config'

export const tagTypes = [
  'AllViews',
  'AllForms',
  'SingleForm',
  'SingleView',
  'Tabs',
  'Dictionary',
  'Menu',
  'ObjectData',
  'AuditData',
  'DefinedFields',
  'UndefinedFields',
  'Template',
  'Search',
  'UncertainViews',
  'GroupViews',
  'UncertainForms',
  'GroupForms',
  'Criteria',
  'UndefinedViews',
  'FormContainers',
  'EnrichedContainer',
  'DefaultCriteria',
  'Parameter',
  'AllGantt',
  'Restrictions',
  'Criterias',
  'Group',
  'DefaultSA',
  'SingleGantt',
  'AllContexts',
  'SingleContext',
  'ContextViews',
  'ContextGanttCharts',
  'ControlPanels',
  'SingleControlPanel',
  'ContextControlPanel',
  'ContextMenuList',
  'SingleContextMenu',
  'AllVariables',
  'SingleVariable',
  'ContextVariables',
  'AllStyleSettings',
  'SingleStyleSetting',
  'ObjectStateValue',
  'SingleObjectStateValue',
  'AllObjectStates',
  'SingleObjectState',
  'ObjectState',
]

export const commonAPI = createApi({
  reducerPath: 'commonApi',
  tagTypes,
  baseQuery: baseQueryWithNotifications,
  endpoints: () => ({}),
})
