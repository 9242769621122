import { FC, MouseEvent, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import isString from 'lodash/isString'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import more from '@assets/images/more.svg'
import { FormInput } from '@microservices/wiskey-react-components'
import { Grid, IconButton, InputAdornment, SxProps, Typography, useTheme } from '@mui/material'

import { RowStylesWrapper } from '@pages/ConfiguredEntity/components/RowStylesWrapper'

import { CustomSvgIcon } from '@components/CustomSvgIcon/CustomSvgIcon'
import { FormContext } from '@components/DisplayForm'
import { getBooleanSelectInputProps } from '@components/DisplayForm/helpers'
import { checkValidElementInLine } from '@components/DisplayForm/helpers/checkValidElementInLine'
import { useDisplayFormState, useFormFields } from '@components/DisplayForm/hooks'
import { FormInputWrapper } from '@components/FormInputWrapper'
import { EnumAutocompleteController } from '@components/hookFormControllers/EnumAutocompleteController'
import { MappedCheckboxForForm } from '@components/MappedCheckbox'
import { MaskedTimeInputForForm } from '@components/MaskedTimeInputForForm/MaskedTimeInputForForm'

import { useFetchObjectDataMutation } from '@redux/api'
import { openFormDialog, openStringEditDialog } from '@redux/reducers/dialogWindowManager.reducer'
import { addFormHistory, createHistoryByForm } from '@redux/reducers/formHistory.reducer'
import { showMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch, useAppSelector } from '@hooks'
import {
  generalFieldStylesByMode,
  getFormCodeFromExpandsParams,
  getFormCodeFromParams,
  getInitialPositionWindow,
  getPlaceholderByField,
  getSecondsFromTime,
  getTimeFromSeconds,
  isObjectValueType,
  transformParamsToExpands,
} from '@helpers'
import {
  BIND_TYPE,
  ENTITY,
  FIELD_VALUE_FORMAT,
  FIELD_VALUE_TYPE,
  FORM_ELEMENT_TYPE,
  FORM_FIELD_LABEL_POSITION,
  FORM_TYPE,
  GENERATOR_INPUT_TYPE,
  LABEL_PLACEMENT_RECORD,
  MIN_HEIGHT_FIELD,
  MIN_HEIGHT_FIELD_DIALOG,
  OBJECT_FIELD_TYPE,
  REGEX,
  UPDATES_ROW_CLASSNAME,
} from '@constants'
import {
  DIALOG_WINDOW_TYPE,
  FieldObjectParams,
  FormDataEnriched,
  FormElement,
  FormLine,
  FormRow,
  NewObjectDataLinkType,
  Row,
} from '@types'

import { ObjectPickerController } from '../../../hookFormControllers/ObjectPickerController'
import { EmbeddedListControl } from '../EmbeddedListControl'
import { JSField } from '../JSField'

export const LinesWithElements: FC<{
  lines: FormLine<FormDataEnriched>[]
  tabId: number
  heigthForListControl: number
}> = ({ lines, tabId, heigthForListControl }) => {
  const { t } = useTranslation()
  const {
    form,
    row,
    links,
    onSetNewForm,
    isFetchingForm,
    viewPath,
    onResetEdit,
    objectId,
    type: formType,
    isViewEdit,
    isDropdownWindowOpenFormState,
    handleSetDropdownWindowOpenFormState,
    initialCommonParameters,
    globalId,
    currentLink,
    dialogId,
    isLoadingRow,
    watch,
    setValueOnForm,
    isSaveByEnterDisabled,
    handleSetSaveByEnterDisabled,
    formCodeToFetchBy,
  } = useContext(FormContext)
  const {
    isViewType,
    isDirty,
    dirtyFields: dirtyFieldsObj,
    isFormDialogWindow,
  } = useDisplayFormState()
  const dispatch = useAppDispatch()
  const { objectsWithDirtyRecords } = useAppSelector(state => state.changedFormFields)
  const { dialogWindows } = useAppSelector(state => state.dialogWindowManager)

  const theme = useTheme()
  const [fetchExpandObjectRecord] = useFetchObjectDataMutation()

  const formFields = useFormFields({ formCode: formCodeToFetchBy })

  const handleNavigateOtherForm = (
    { objectId, objectCode, formCode, title, isShiftKey }: NewObjectDataLinkType,
    event?: MouseEvent<HTMLButtonElement>
  ) => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }
    if (form && !isFetchingForm) {
      const lastLink = links?.at(-1) || currentLink
      if (lastLink) {
        if (isShiftKey) {
          const id = uuid()
          const initialPosition = event ? getInitialPositionWindow(event) : undefined
          const openedFormsCount = dialogWindows.filter(
            dialog =>
              [DIALOG_WINDOW_TYPE.FORM, DIALOG_WINDOW_TYPE.VIEW].includes(dialog.type) &&
              !dialog.hidden
          ).length
          const maxOpenedFormsCount = Number(initialCommonParameters.numberOfWindows)

          if (openedFormsCount === maxOpenedFormsCount) {
            dispatch(
              showMessage({
                type: 'info',
                text: t('error.dynamicFormsExceeded', { count: maxOpenedFormsCount }),
              })
            )

            return
          }

          dispatch(
            openFormDialog({
              id,
              parentDialogId: null,
              type: DIALOG_WINDOW_TYPE.FORM,
              title: null,
              meta: {
                formCode,
                objectId,
                globalId: id,
                // TODO Возможно стрельнет отсутствие viewCode, значение path, entityType и event
                viewCode: '',
                path: formCode,
                entityType: ENTITY.VIEW,
                event: FORM_TYPE.VIEW,
                parentFormId: globalId,
              },
              initialPosition,
            })
          )

          dispatch(
            createHistoryByForm({
              id,
              parentForm: currentLink,
              childForm: {
                formCode,
                objectCode,
                objectId,
                event: currentLink.event,
                title,
                id,
                isWindow: true,
              },
            })
          )

          return
        }
        const id = uuid()

        // если форма была открыта по ссылке
        if (!links) {
          dispatch(
            createHistoryByForm({
              id: globalId,
              parentForm: currentLink,
              childForm: {
                formCode,
                objectCode,
                objectId,
                event: currentLink.event,
                title,
                id,
                isWindow: false,
              },
            })
          )

          onSetNewForm({ objectCode, objectId, formCode, event: currentLink.event })
          onResetEdit()

          return
        }

        dispatch(
          addFormHistory({
            id: globalId,
            form: {
              formCode,
              objectCode,
              objectId,
              event: currentLink.event,
              title,
              id,
              isWindow: true,
            },
          })
        )

        // TODO: проверить может стоит убрать
        onSetNewForm({ objectCode, objectId, formCode, event: lastLink.event })
        onResetEdit()
      }
    }
  }

  const renderJSValueField = (value: string, row: Row) => {
    const renderValue = new Function('srcObj', 'moment', value)
    try {
      const result = renderValue(row, moment)

      return `${result}`.trim()
    } catch {
      return undefined
    }
  }

  const getInputTypeByValueType = (valueType: FIELD_VALUE_TYPE | null): GENERATOR_INPUT_TYPE => {
    switch (valueType) {
      case FIELD_VALUE_TYPE.DATETIME:
        return GENERATOR_INPUT_TYPE.DATE_TIME_PICKER
      case FIELD_VALUE_TYPE.BOOLEAN:
        return GENERATOR_INPUT_TYPE.SELECT
      default:
        return GENERATOR_INPUT_TYPE.INPUT
    }
  }

  const getTypeByValueType = (valueType: FIELD_VALUE_TYPE | null) => {
    if (valueType === FIELD_VALUE_TYPE.DOUBLE || valueType === FIELD_VALUE_TYPE.INTEGER) {
      return 'number'
    }

    return
  }

  const handleGetFormCodeParams = ({
    field,
    objectId,
    fieldParams,
  }: {
    fieldParams: FieldObjectParams
    objectId: string
    field: FormRow
  }) => {
    const transformParams = transformParamsToExpands(fieldParams)
    const paramsForExpandsRequest = transformParams.map(item => item.expandRequest)

    fetchExpandObjectRecord({
      objectCode: field.embeddedObjectCode,
      objectId,
      expands: paramsForExpandsRequest,
    })
      .unwrap()
      .then(res => {
        if (field.embeddedObjectCode) {
          const findFormCodeByExpandedFieldValue = getFormCodeFromExpandsParams(
            transformParams,
            res
          )
          const findFormCodeByFieldValue = getFormCodeFromParams(fieldParams, res)
          const defaultFormCode = isString(fieldParams.formCode) && fieldParams.formCode

          if (findFormCodeByFieldValue) {
            handleNavigateOtherForm({
              formCode: findFormCodeByExpandedFieldValue || findFormCodeByFieldValue,
              objectId,
              objectCode: field.embeddedObjectCode,
              title: findFormCodeByFieldValue,
              isShiftKey: false,
            })

            return
          }
          if (defaultFormCode) {
            handleNavigateOtherForm({
              formCode: defaultFormCode,
              objectId,
              objectCode: field.embeddedObjectCode,
              title: defaultFormCode,
              isShiftKey: false,
            })
          }
        }
      })
  }

  const renderInput = ({
    data,
    titleHidden,
    labelPosition = FORM_FIELD_LABEL_POSITION.TOP,
  }: FormElement<FormDataEnriched>) => {
    const field = data as FormRow

    const objectWithFields = objectsWithDirtyRecords[currentLink.objectCode]
    const dirtyFields = (objectWithFields && objectWithFields[row?._id]) || []

    const className = dirtyFields.includes(field.code) ? UPDATES_ROW_CLASSNAME : undefined

    const userRequired = field.userRequired ?? false
    const isFieldRequired = userRequired || field.defaultRequired

    const fieldLabel = titleHidden ? undefined : field.title
    const fieldlabelPlacement = LABEL_PLACEMENT_RECORD[labelPosition]

    const readOnly = isViewType || !field.editField
    const disabled = readOnly
    const placeholder = getPlaceholderByField(field)

    const watchCurrentField = watch(field.code)

    const labelWidthSx: SxProps = {
      width: undefined,
      minWidth: undefined,
      flexBasis: 120,
      ...(fieldlabelPlacement === 'top' && {
        width: '100%',
        flexBasis: undefined,
      }),
    }

    const handleValidateValue = (value: string) => {
      // return REGEX.OBJECT_PICKER_FORBIDDEN_CHARS.test(value)
      return true
    }

    const handleSetOuterValueForMappedCheckbox = (value: number | boolean) => {
      setValueOnForm(field.code, value as unknown as never, {
        shouldDirty: true,
      })
    }

    const handleSetOuterValueForTime = (value: string) => {
      setValueOnForm(field.code, getSecondsFromTime(value) as unknown as never, {
        shouldDirty: true,
      })
    }

    const handleSetOuterValueStringEdit = (value: string) => {
      setValueOnForm(field.code, value as unknown as never, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }

    const handleOpenWindowStringEdit = () => {
      // для открытия формы по нажатию на иконку "стрелка вправо" или по двойному клику на строку во вью смотрим на isViewEdit,
      // через открытие формы на редактирование на иконку "карандаш" смотрим на formType === FORM_TYPE.EDIT
      // также при создании формы также можно редактировать строковое поле в отдельном окне
      const isEdit = formType === FORM_TYPE.EDIT || isViewEdit || formType === FORM_TYPE.CREATE
      dispatch(
        openStringEditDialog({
          id: uuid(),
          parentDialogId: dialogId,
          type: DIALOG_WINDOW_TYPE.STRING_EDIT,
          title: t('stringEdit.title', { field: field.title }),
          meta: {
            text: watchCurrentField ?? row[field.code],
            setOuterValue: handleSetOuterValueStringEdit, // TODO исправить на ивенты, тк много ошибок в консоли
            readonly: !isEdit || !field?.editField,
          },
        })
      )
    }

    const asCheckbox =
      field.valueFormat === FIELD_VALUE_FORMAT.NUMBER ||
      field.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN

    if (asCheckbox && field.valueFormat) {
      return (
        <MappedCheckboxForForm
          formRelatedProps={{
            dirtyFields: dirtyFieldsObj,
            field: field.code,
            name: field.code,
            updateField: Boolean(className),
            isDialogWindow: isFormDialogWindow,
            label: fieldLabel,
            labelPosition: fieldlabelPlacement,
          }}
          mappedCheckboxProps={{
            valueFormat: field.valueFormat,
            disabled,
            outerValue: watchCurrentField as unknown as boolean | number,
            setOuterValue: handleSetOuterValueForMappedCheckbox,
            checkboxSx: {
              top: 0,
              background: theme => (isViewEdit ? theme.palette.background.default : 'inherit'),
            },
          }}
        />
      )
    }

    if (field.valueFormat === FIELD_VALUE_FORMAT.TIME) {
      return (
        <MaskedTimeInputForForm
          formRelatedProps={{
            dirtyFields: dirtyFieldsObj,
            field: field.code,
            name: field.code,
            updateField: Boolean(className),
            isDialogWindow: isFormDialogWindow,
            label: fieldLabel,
            labelPosition: fieldlabelPlacement,
            labelWidthSx,
          }}
          inputMaskProps={{
            outerValue: getTimeFromSeconds(Number(watchCurrentField)),
            setOuterValue: handleSetOuterValueForTime,
            disabled,
            placeholder,
          }}
        />
      )
    }

    if (isObjectValueType(field.valueType)) {
      return (
        <ObjectPickerController
          key={field.code}
          className={className}
          dirtyFields={dirtyFieldsObj}
          disabled={disabled}
          dropDownList={field.dropDownList}
          dropDownWindow={field.dropDownWindow}
          fieldId={Number(field.id)}
          formId={Number(form?.id)}
          formObjectCode={currentLink.objectCode}
          formObjectId={objectId}
          formType={formType}
          getValue={handleNavigateOtherForm}
          handleSetDropdownWindowOpenFormState={handleSetDropdownWindowOpenFormState}
          isDialogWindow={isFormDialogWindow}
          isDropDownList={Boolean(field.dropDownList)}
          isDropDownWindow={Boolean(field.dropDownWindow)}
          isDropdownWindowOpenFormState={isDropdownWindowOpenFormState}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          isViewEdit={isViewEdit}
          label={fieldLabel}
          labelPlacement={fieldlabelPlacement}
          name={field.code}
          objectCode={field.embeddedObjectCode}
          objectFormCode={field.objectFormCode}
          objectFormCodeparams={field.params}
          objectFormTitle={field.objectFormTitle}
          objectValue={field.objectValue}
          parentDialogId={dialogId}
          placeholder={placeholder}
          readOnly={readOnly}
          rules={{ required: isFieldRequired }}
          tabId={tabId}
          valueType={field.valueType}
          getFormCodeParams={(fieldParams, objectId) =>
            handleGetFormCodeParams({ fieldParams, objectId, field })
          }
          labelSx={{
            color: theme.palette.color.formCardLabel,
            fontWeight: 400,
            letterSpacing: 0.5,
            ...labelWidthSx,
          }}
          onValidateInputValue={handleValidateValue}
        />
      )
    }

    if (field.type === OBJECT_FIELD_TYPE.ENUM) {
      return (
        <EnumAutocompleteController
          className={className}
          dirtyFields={dirtyFieldsObj}
          disabled={disabled}
          fieldCode={field.value}
          isDialogWindow={isFormDialogWindow}
          label={fieldLabel}
          labelPlacement={fieldlabelPlacement}
          name={field.code}
          objectCode={field.objectCode ?? ''}
          placeholder={placeholder}
          readOnly={readOnly}
          rules={{ required: isFieldRequired }}
          valueType={field.valueType as FIELD_VALUE_TYPE}
          labelSx={{
            color: theme.palette.color.formCardLabel,
            fontWeight: 400,
            letterSpacing: 0.5,
            ...labelWidthSx,
          }}
        />
      )
    }

    if (field.bindType === BIND_TYPE.JS) {
      return (
        <JSField
          key={field.code}
          className={className}
          dirtyFields={dirtyFieldsObj}
          isDialogWindow={isFormDialogWindow}
          label={fieldLabel}
          labelPlacement={fieldlabelPlacement}
          name={field.code}
          placeholder={placeholder}
          value={renderJSValueField(field.value, row)}
          labelSx={{
            color: theme.palette.color.formCardLabel,
            fontWeight: 400,
            letterSpacing: 0.5,
            ...labelWidthSx,
          }}
        />
      )
    }

    return (
      <FormInputWrapper
        enterDisabled={isSaveByEnterDisabled}
        handleSetEnterDisabled={handleSetSaveByEnterDisabled}
        valueType={field.valueType}
      >
        <FormInput
          {...(field.valueType === FIELD_VALUE_TYPE.BOOLEAN
            ? getBooleanSelectInputProps({
                dirtyFieldsObj,
                booleanSelectValue: watchCurrentField,
                field,
                className,
                isFormDialogWindow,
                handleSetOuterValueForMappedCheckbox,
              })
            : {})}
          key={field.code}
          applyDateByWholeNumber={true}
          disabled={disabled}
          formatDate={field.formatDate || undefined}
          inputType={getInputTypeByValueType(field.valueType)}
          isGetNullWhenBlur={false}
          label={fieldLabel}
          labelPlacement={fieldlabelPlacement}
          multiline={field.isMultiline}
          name={field.code}
          placeholder={placeholder}
          placeholderDatePicker={placeholder}
          popoverDateShiftMode={true}
          readOnly={readOnly}
          rules={{ required: isFieldRequired }}
          showEstimatedDate={true}
          type={getTypeByValueType(field.valueType)}
          PopperProps={{
            sx: {
              zIndex: theme.zIndex.tooltip,
            },
          }}
          errorIconSxForDate={{
            top: '1px',
            right: '28px',
          }}
          formLabelSx={{
            '*': {
              transition: '0.2s ease-in',
            },
            '& > .MuiFormControl-root': {
              ...generalFieldStylesByMode(
                theme,
                dirtyFieldsObj,
                field.code,
                Boolean(className),
                isFormDialogWindow
              ),
              '& .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root': {
                padding: isFormDialogWindow ? 0 : 1,
              },
            },
          }}
          labelSx={{
            ...labelWidthSx,
            '*': {
              transition: '0.2s ease-in',
            },
            fontWeight: 400,
            letterSpacing: 0.5,
            color: theme.palette.color.default,
          }}
          replacePattern={
            field.valueType === FIELD_VALUE_TYPE.INTEGER ? REGEX.REPLACE_ONLY_NUMBER : undefined
          }
          textFieldInputProps={{
            ...(field.valueType === FIELD_VALUE_TYPE.STRING && {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton size={'small'} onClick={() => handleOpenWindowStringEdit()}>
                    <CustomSvgIcon src={more} sx={{ fontSize: '1rem' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }),
          }}
        />
      </FormInputWrapper>
    )
  }

  const generateElement = (element: FormElement<FormDataEnriched>) => {
    const { data } = element
    const isListControl = data instanceof Object && 'objectCode' in data

    switch (element.type) {
      case FORM_ELEMENT_TYPE.FIELD:
        return renderInput(element)
      case FORM_ELEMENT_TYPE.SPACE:
        return <></>
      case FORM_ELEMENT_TYPE.TEXT:
        return (
          <Typography
            title={element.title}
            sx={{
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: element.textAlign ? element.textAlign.toLowerCase() : 'left',
            }}
          >
            {element.title}
          </Typography>
        )
      case FORM_ELEMENT_TYPE.VIEW:
        if (isListControl) {
          return (
            <EmbeddedListControl
              entityCode={data.code}
              entityId={data.id}
              formElementId={element.id}
              formObjectCode={currentLink.objectCode}
              formObjectId={currentLink.objectId || objectId}
              getLeaveForm={() => isDirty && !confirm(t('notifications.leave'))}
              isListControlDialogWindow={isFormDialogWindow}
              isNestedView={true}
              parentFormIsDirty={isDirty}
              path={viewPath}
              title={data.title}
              type={ENTITY.LIST_CONTROL}
              windowHeight={heigthForListControl}
              onResetEditForm={onResetEdit}
            />
          )
        }
    }
  }

  return (
    <Grid container spacing={1}>
      {lines.map(line => (
        <Grid key={line.id} container item alignItems={'flex-start'} spacing={1}>
          {line.elements.map(element => {
            const isValidElement = checkValidElementInLine(formFields, element)

            return isValidElement ? (
              <Grid
                key={element.id}
                item
                minHeight={isFormDialogWindow ? MIN_HEIGHT_FIELD_DIALOG : MIN_HEIGHT_FIELD}
                xs={element.size}
                sx={{
                  // pointerEvents: `${!element?.data?.editField}` ? 'none' : 'auto',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <RowStylesWrapper isDialogWindow={isFormDialogWindow}>
                  {generateElement(element)}
                </RowStylesWrapper>
              </Grid>
            ) : null
          })}
        </Grid>
      ))}
    </Grid>
  )
}
