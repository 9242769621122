import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import logo from '@assets/images/logo.svg'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar as MUIAppBar, Grid, IconButton, List, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Profile } from '@components/AppBar/components/Profile'
import { AppLoader } from '@components/AppLoader'
import { CustomSvgIcon } from '@components/CustomSvgIcon'
import { UserNavList } from '@components/UserNavList'

import { DRAWER_WIDTH } from '@constants'
import { TransformLinesForAppBarType } from '@types'

type AppBarProps = {
  open: boolean
  options: TransformLinesForAppBarType
  loadingOptions: boolean
  isAdmin: boolean
  setOpen?: (sidebarOpen: boolean) => void
}

const StyledAppBar = styled(MUIAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<Omit<AppBarProps, 'setOpen' | 'options' | 'loadingOptions'>>(({ theme, open, isAdmin }) => ({
  zIndex: theme.zIndex.drawer + 1,
  '& .MuiToolbar-root': {
    paddingLeft: theme.spacing(2),
  },
  padding: '0 !important',
  alignItems: 'center',
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    isAdmin && {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}))

export const AppBar: FC<AppBarProps> = ({ options, loadingOptions, open, setOpen, isAdmin }) => {
  const { t } = useTranslation()
  const handleClick = () => {
    setOpen?.(true)
  }

  return (
    <StyledAppBar isAdmin={isAdmin} open={open} position='static'>
      <Grid
        container
        alignItems={'center'}
        bgcolor={theme => theme.palette.primary.main}
        flexWrap={'nowrap'}
        py={'4px'}
      >
        <Grid container item alignItems={'center'}>
          <Grid item>
            {!open && (
              <IconButton
                sx={{
                  mr: 3,
                  color: theme => theme.palette.background.paper,
                  '& .MuiTouchRipple-child': { borderRadius: 'inherit' },
                  borderRadius: 0,
                }}
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Grid>
          <Grid
            item
            sx={{
              ml: '19px',
              mr: '12px',
              pt: '7px',
              alignItems: 'center',
            }}
          >
            <CustomSvgIcon src={logo} sx={{ width: 24, height: 26 }} />
          </Grid>
          <Grid item>
            <Typography color={'white'} sx={{ fontWeight: 400, lineHeight: '32px', fontSize: 18 }}>
              {t('appbar.title.user')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item justifySelf={'flex-end'} mr={'27px'}>
          <Profile />
        </Grid>
      </Grid>

      {loadingOptions ? (
        <List
          key='loadingApp'
          sx={{
            display: 'flex',
            pt: 1,
            pb: 1,
            bgcolor: theme => theme.palette.background.topMenu,
            width: '100%',
            overflowX: 'auto',
            alignItems: 'center',
          }}
        >
          <AppLoader size='2rem' />
        </List>
      ) : (
        options.map((line, index) => (
          <List
            key={line.id}
            sx={{
              display: 'flex',
              pt: index === 0 ? 1 : 0,
              pb: 0.7,
              // px: 1,
              bgcolor: theme => theme.palette.background.topMenu,
              width: '100%',
              overflowX: 'auto',
              alignItems: 'center',
            }}
          >
            {line.items.map(option => (
              <UserNavList key={option.id} option={option} />
            ))}
          </List>
        ))
      )}
    </StyledAppBar>
  )
}
